import { UserBlob, api } from 'api'
import { QueryResult, useQuery } from 'react-query'
import { AxiosError } from 'axios'

export const loginWithSessionId = async (key: string, sessionId: string): Promise<UserBlob> => {
  const response = await api.accounts.loginWithSessionId(sessionId)

  return response.data
}
export const useLoginWithSessionId = (sessionId: string | null): QueryResult<UserBlob, AxiosError<any>> =>
  useQuery<UserBlob, AxiosError>(['loginWithSessionId', sessionId], loginWithSessionId, { refetchOnWindowFocus: false })
