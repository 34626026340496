import React, { useContext, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { formatISO, parseISO } from 'date-fns'

import { AuthenticationContext } from 'contexts'

export const StyledTsInfoBox = styled.div`
  border-radius: ${props => props.theme.borderRadius};
  background: #f5f5f5;
  padding: 0.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
`

const reformatDate = (dateString: string) => formatISO(parseISO(dateString), { representation: 'date' })

export function TsInfo() {
  const authContext = useContext(AuthenticationContext)
  const { t } = useTranslation()

  const latestInfo = authContext.data?.LatestInfofromTs
  const date = useMemo(() => (latestInfo ? reformatDate(latestInfo) : null), [latestInfo])

  if (!date) return null
  return (
    <StyledTsInfoBox>
      {t('services:data-last-fetched-from-ts')}:<br />
      {date}
    </StyledTsInfoBox>
  )
}
