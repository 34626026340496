import { createGlobalStyle } from 'styled-components/macro'
import { responsiveUp } from './breakpoints'

export const theme = {
  colors: {
    primary: '#004851',
    primaryLight: '#527A7F',
    primaryDark: '#003C43',
    secondary: '#00968F',
    secondaryLight: '#12A49E',
    secondaryThinLight: '#D9F0EE',
    secondaryThin: '#00968F08',
    accent: '#FF8200',
    accentFocused: '#e2790b',
    accentThin: 'black',
    black: '#333333',
    red: '#D0021B',
    lightGray: '#f5f5f5',
    green: '#2BA329',
  },
  borderRadius: '.25rem',
  sizes: {
    container: '100%',
    smContainer: '36rem',
    mdContainer: '48rem',
    xlContainer: '82rem',
  },
}

export const GlobalStyle = createGlobalStyle`
  /* Fonts */

  @font-face {
    font-family: 'Brokman';
    font-style: normal;
    font-weight: 400;
    src: local('Brokman Std Regular'), local('Brokman Std Regular'),
      url('/fonts/brokman/brokman-std-regular.woff') format('woff'), /* IE11 Browsers */
      url('/fonts/brokman/brokman-std-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/brokman/brokman-std-regular.otf') /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Brokman';
    font-style: normal;
    font-weight: 300;
    src: local('Brokman Std Light'), local('Brokman Std Light'),
      url('/fonts/brokman/brokman-std-light.woff') format('woff'), /* IE11 Browsers */
      url('/fonts/brokman/brokman-std-light.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/brokman/brokman-std-light.otf') /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Brokman';
    font-style: normal;
    font-weight: 500;
    src: local('Brokman Std Medium'), local('Brokman Std Medium'),
    url('/fonts/brokman/brokman-std-medium.woff') format('woff'), /* IE11 Browsers */
      url('/fonts/brokman/brokman-std-medium.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/brokman/brokman-std-medium.otf') /* Safari, Android, iOS */
  }
  @font-face {
    font-family: 'Brokman';
    font-style: normal;
    font-weight: 600;
    src: local('Brokman Std Bold'), local('Brokman Std Bold'),
      url('/fonts/brokman/brokman-std-bold.woff') format('woff'), /* IE11 Browsers */
      url('/fonts/brokman/brokman-std-bold.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/brokman/brokman-std-bold.otf') /* Safari, Android, iOS */
  }

  /* Reset */

  * {
    margin: 0;
    padding: 0;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  /* :focus, label:focus-within {
    outline: ${theme.colors.secondary} dashed 2px;
    outline-offset: 1px;
  } */

  :active {
    outline: none;
  }

  body {
    font-family: 'Brokman', Arial, sans-serif;
    font-size: 16px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    color: white;
    background: ${theme.colors.primary};
  }

  /* Typography */

  a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    border-bottom: 1px solid currentColor;
  }

  a:not(.btn):hover {
    border-bottom-width: 2px;
  }

  p {
    line-height: 1.5;
  }

  p + p {
    margin-top: 1rem;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;

    &:last-child:not(.label) {
      margin-bottom: 0;
    }
  }

  h1 {
    margin-bottom: 1em;

    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    /* asd */
    font-size: 1.25rem;
  }

  ol, ul {
    padding: 0 1.5rem;
    line-height: 1.5;
  }

  li b {
    font-weight: 500;
  }

  /* Forms */

  .form-group:not(:last-child) {
    margin-bottom: 2rem;
  }

  label, .label {
    display: block;

    margin-bottom: 0.75rem;

    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
  }

  textarea,
  select {
    appearance: none;

    width: 100%;

    font: inherit;
    line-height: 1.5;

    background: white;

    border: 1px solid ${theme.colors.secondary};
    border-radius: ${theme.borderRadius};
  }

  select {
    height: 3rem;

    padding: 0 1rem;
  }

  textarea {
    padding: 0.75rem 1rem;
  }

  /* Layout */

  .right {
    text-align: right;
  }

  .container {
    width: ${theme.sizes.container};

    margin: 0 auto;

    padding: 0 0.3rem;


    ${responsiveUp.sm`
      padding: 0 1rem;
    `}

    @media screen and (min-width: ${theme.sizes.mdContainer}) {
      width: ${theme.sizes.mdContainer};
    }

    @media screen and (min-width: ${theme.sizes.xlContainer}) {
      width: ${theme.sizes.xlContainer};
    }
  }
`
