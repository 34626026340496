import * as React from 'react'
import * as Sentry from '@sentry/react'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from 'styled-components/macro'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'

import { AppRouter } from '../pages'
import { PuzzelChat } from 'components/PuzzelChat'

import i18nConfig from '../config/i18n'

import { GlobalStyle, theme } from '../theme'

import { AuthenticationProvider, VehiclesProvider } from '../contexts'

const queryCache = new QueryCache()

export const App: React.FC = () => (
  <>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18nConfig}>
          <Sentry.ErrorBoundary showDialog={true}>
            <GlobalStyle />
            <AuthenticationProvider>
              <VehiclesProvider>
                <AppRouter />
                <PuzzelChat />
              </VehiclesProvider>
            </AuthenticationProvider>
          </Sentry.ErrorBoundary>
        </I18nextProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryCacheProvider>
  </>
)
