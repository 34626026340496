import * as React from 'react'
import styled from 'styled-components/macro'
import { FieldProps } from 'formik'

const StyledToggle = styled.label`
  margin-bottom: 0;

  .toggle-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    width: 5rem;
    height: 1.5rem;

    margin-top: 0.125rem;

    background: #f3f3f3;

    border: 1px solid ${props => props.theme.colors.secondary};
    border-radius: ${props => props.theme.borderRadius};

    .toggle-handle {
      width: 2.5rem;
      height: 2rem;

      margin: 0 -1px;

      background: white;

      border: 1px solid ${props => props.theme.colors.secondary};
      border-radius: ${props => props.theme.borderRadius};
    }
  }

  .toggle-checkbox:checked ~ .toggle {
    justify-content: flex-end;

    background: ${props => props.theme.colors.secondary};
  }
`

interface Values {
  field: string
}

export interface ToggleProps extends Partial<FieldProps<Values>> {
  form: any
}

export const Toggle: React.FunctionComponent<ToggleProps> = ({ field, ...props }) => {
  const { value } = field || {}
  return (
    <StyledToggle>
      <input
        className="toggle-checkbox"
        type="checkbox"
        {...field}
        {...props}
        value={value?.field} // FIXME: Är detta korrekt?
      />
      <span className="toggle">
        <span className="toggle-handle" />
      </span>
    </StyledToggle>
  )
}

/** @component */
export default React.memo(Toggle, (prev, next) => {
  if (prev.field && next.field && prev.field.value !== next.field.value) {
    return false
  }

  return true
})
