import React from 'react'
import styled from 'styled-components/macro'

const tips = [
  {
    title: 'Så undviker du vanliga fel inför besiktningen',
    href:
      'https://opus.se/nyheter/nyheter/2020-03-04-sa-undviker-du-vanliga-fel-infor-besiktningen?qs=S%C3%A5%20undviker%20du%20vanliga%20fel%20inf%C3%B6r%20besiktningen',
  },

  {
    title: 'Se upp för vilt på vägarna',
    href: 'https://opus.se/nyheter/nyheter/2020-08-24-se-upp-for-vilt-pa-vagen?qs=vilt',
  },

  {
    title: 'Har du koll på däcken?',
    href: 'https://opus.se/nyheter/nyheter/2019-10-17-har-du-koll-pa-dacken?qs=Har%20du%20koll%20p%C3%A5%20d%C3%A4cken',
  },

  {
    title: 'Bilexpertens vintertips för din bil',
    href:
      'https://opus.se/nyheter/nyheter/2019-12-12-bilexpertens-vintertips-for-din-bil?qs=Bilexpertens%20vintertips%20f%C3%B6r%20din%20bil',
  },

  {
    title: 'Så här tvättar du bilen rätt',
    href:
      'https://opus.se/nyheter/nyheter/2019-07-10-smutsig-bil-sa-har-tvattar-du-pa-basta-satt?qs=tv%C3%A4tta%20bilen',
  },

  { title: 'Opus tar ett miljöansvar', href: 'https://opus.se/miljoansvar' },

  {
    title: 'Att bila med barn i bilen',
    href: 'https://opus.se/nyheter/nyheter/2020-06-25-att-bila-med-barn-i-bilen?qs=Att%20bila%20med%20barn%20i%20bilen',
  },

  {
    title: 'Importera bil från utlandet',
    href:
      'https://opus.se/nyheter/nyheter/2020-02-17-importera-bil-fran-utlandet?qs=Importera%20bil%20fr%C3%A5n%20utlandet',
  },
]

const TipsList = styled.ul`
  padding: 0;
`
const TipsListItem = styled.li`
  list-style-type: none;
  position: relative;
  padding-left: 2rem;
  margin: 1rem 0;

  a {
    font-weight: bold;
  }

  &::before {
    content: '\u27a4';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    color: ${props => props.theme.colors.primary};
  }
`

export function TipsPartial() {
  return (
    <>
      <h3>Tips till dig!</h3>
      <p>Här har vi samlat bra tips och råd om ditt fordon från några av alla artiklar som finns på vår webb.</p>
      <TipsList>
        {tips.map((tip, idx) => (
          <TipsListItem key={idx}>
            <a href={tip.href} rel="noopener noreferrer" target="_blank">
              {tip.title}
            </a>
          </TipsListItem>
        ))}
      </TipsList>
    </>
  )
}
