import * as React from 'react'
import styled from 'styled-components/macro'

/* Import utilities here */

/* Import components here */

// CSS
const StyledFormErrorMessage = styled.span`
  display: block;

  padding: 0.5rem;
  margin-top: 0.5rem;

  font-weight: 600;

  color: white;
  background: ${props => props.theme.colors.red};

  border-radius: 0.25rem;

  &:first-child {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`
// Component
export const FormErrorMessage: React.FunctionComponent = ({ children, ...props }) => {
  return <StyledFormErrorMessage {...props}>{children}</StyledFormErrorMessage>
}

/** @component */
export default FormErrorMessage
