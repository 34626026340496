import * as React from 'react'
import styled from 'styled-components/macro'

export const Sidebar = React.memo(styled.aside`
  @media screen and (min-width: ${props => props.theme.sizes.xlContainer}) {
    margin-top: 4rem;
  }
`)

export default Sidebar
