import styled from 'styled-components/macro'

export const StyledHelpSection = styled.section`
  margin-top: 4rem;

  h2 {
    margin-bottom: 2rem;
  }
`

export const StyledHelpLink = styled.span`
  display: block;

  & + & {
    margin-top: 1rem;
  }

  a {
    line-height: 1.5;
  }
`
