import { useContext, useEffect, useState } from 'react'
import { UserBlob } from '@cdab/opus-api-client'
import { AuthenticationContext } from '../contexts'
import { api } from '../api'

export function useAuthCheck(): boolean {
  const [isCheckingLogin, setIsCheckingLogin] = useState(true)
  const authenticationContext = useContext(AuthenticationContext)

  useEffect(() => {
    async function authCheck(): Promise<void> {
      const tokenData: UserBlob = JSON.parse(window.sessionStorage.getItem('.customer-portal_token-data') || '{}')

      if (!tokenData || !Object.keys(tokenData).length) return

      const { Jwt } = tokenData

      if (!Jwt) return
      api.setToken(Jwt)

      try {
        await api.accounts.isLoggedIn()
        authenticationContext.login(tokenData)
      } catch (error) {
        if (error?.response?.status === 401) {
          api.logout()
          return
        } else {
          console.error(error)
        }
      }
    }

    if (!authenticationContext.isAuthenticated) {
      authCheck().finally(() => setIsCheckingLogin(false))
    } else {
      setIsCheckingLogin(false)
    }
  }, [authenticationContext])

  return isCheckingLogin
}
