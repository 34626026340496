import React, { useContext, useEffect } from 'react'
import styled from 'styled-components/macro'

import { Header } from '../'
import { responsiveDown, responsiveUp } from 'theme/breakpoints'
import { AuthenticationContext, TCustomerType } from 'contexts'
import { useTranslation } from 'react-i18next'

const StyledPage = React.memo(styled.main`
  padding: 0;

  ${responsiveDown.sm`
    margin-bottom: 4rem !important;
  `}

  ${responsiveUp.xs`
    padding: 2rem 1rem !important;
  `}
`)

export interface PageProps {
  portalType?: TCustomerType
  hideNav?: boolean
  hideType?: boolean
  buttonArea?: JSX.Element
}

/**
 * A container page to wrap individual pages
 */
const PageComponent: React.FunctionComponent<PageProps> = ({ portalType, hideNav, hideType, buttonArea, children }) => {
  const { t } = useTranslation('app')
  const authContext = useContext(AuthenticationContext)
  const [userType, setUserType] = React.useState<TCustomerType | undefined>(undefined)

  useEffect(() => {
    // If user is logged in then CustomerType defines the text
    if (portalType === undefined && authContext.customerType) {
      if (authContext.customerType === 'private') {
        setUserType('private')
      } else if (authContext.customerType === 'company') {
        setUserType('company')
      } else {
        setUserType(undefined)
      }
    }
  }, [authContext.customerType, authContext.data, portalType, t, userType])

  return (
    <>
      <Header buttonArea={buttonArea} hideNav={hideNav} hideType={hideType} userType={portalType || userType} />
      <StyledPage className="container">{children}</StyledPage>
    </>
  )
}

/** @component */
export const Page = PageComponent
