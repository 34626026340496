import React, { useEffect } from 'react'

import { faCar, faSpinnerThird, faUsers } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import {
  BackgroundCard,
  ErrorText,
  Footer,
  FooterContent,
  LoginTypeBusiness,
  LoginTypeButton,
  LoginTypeIcon,
  PageHeader,
  PageSubHeader,
} from './LandingPage.styles'
import { Page } from 'components/layout'
import { ContentArea, Layout } from 'components/styled'

import { APP_DATA, Routes } from 'config'
import { getCustomerChoice, setCustomerChoice } from 'utils'
import { useTranslation } from 'react-i18next'
import { useInitiateBankIdLogin } from 'hooks/initiateBankIdSession'

/**
 * Landningssida:
 * Vi sparar i localStorage när vi gör ett val.
 * När vi kommer till sidan kollar vi localStorage innan vi gör någonting annat. Om det finns ett tidigare val, redirect'ar vi dit.
 * Om det inte finns något val visar vi landningssidan
 */
export const LandingPage: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation('login')

  const { refetch: initiateBankId, isLoading, error, isFetched } = useInitiateBankIdLogin(false)

  useEffect(() => {
    function getPreviousLoginChoice(): void {
      try {
        const previousChoice = getCustomerChoice()

        if (previousChoice === 'company') {
          history.push(Routes.BusinessLogin)
        }
      } catch (e) {
        Sentry.captureException(e)
      }
    }

    getPreviousLoginChoice()
  }, [history])

  function setCustomerChoiceCompany(): void {
    setCustomerChoice('company')
  }

  /**
   * Initiate a bankdId session. When authenticated the user is redirected to /authenticate
   */
  async function onPersonalLogin(): Promise<void> {
    const res = await initiateBankId()
    setCustomerChoice('private')
    if (res?.RedirectUrl) {
      window.location.assign(res.RedirectUrl)
    }
  }

  function onBusinessLoginClick(): void {
    setCustomerChoiceCompany()
    history.push(Routes.BusinessLogin)
  }

  return (
    <Page hideNav hideType>
      <Layout>
        <ContentArea className="landing-page-content">
          <PageHeader>{t('welcome-to-customerportal')}</PageHeader>
          <PageSubHeader>{t('where-to-login')}</PageSubHeader>
          <BackgroundCard>
            <LoginTypeButton disabled={isLoading || (!error && isFetched)} variant="accent" onClick={onPersonalLogin}>
              <LoginTypeIcon icon={faCar} />
              {!isLoading && !error && !isFetched && <h2>{t('personal')}</h2>}
              {!isLoading && !error && isFetched && <h2>...</h2>}
              {!isLoading && error && <ErrorText>{t('bankid-initiation-failed')}</ErrorText>}
              {isLoading && <FontAwesomeIcon spin icon={faSpinnerThird} />}
              {t('requiresMobileBankId')}
            </LoginTypeButton>

            <LoginTypeBusiness disabled={false} onClick={onBusinessLoginClick}>
              <LoginTypeIcon icon={faUsers} />
              <h2>{t('business')}</h2>
            </LoginTypeBusiness>
          </BackgroundCard>

          <Footer>
            <FooterContent>
              <h2>Opus Bilprovning AB</h2>
              <p>
                Copyright © 2012‑2021.
                <br /> {t('footer-left.all-rights-reserved')}
              </p>
              <p>
                0775 ‑ 500 300
                <br />
                <strong>kundtjanst@opusbilprovning.se</strong>
              </p>
            </FooterContent>
            <FooterContent>
              <h2>{t('footer-right.title')}</h2>
              <p>{t('footer-right.description')}</p>
              <p>
                <a
                  href="https://opus.se/om-oss/anvandarvillkor-for-kundportalen"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <strong>{t('footer-right.personal-data')}</strong>
                </a>
              </p>
              <p>
                <a href={APP_DATA.COOKIE_POLICY_URL} rel="noopener noreferrer" target="_blank">
                  <strong>{t('footer-right.cookie-policy')}</strong>
                </a>
              </p>
            </FooterContent>
          </Footer>
        </ContentArea>
      </Layout>
    </Page>
  )
}
