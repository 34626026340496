import React, { useContext } from 'react'

import styled from 'styled-components/macro'
import { faFilePdf, faLink } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'

import { AuthenticationContext } from 'contexts'

/* Import utilities here */

/* Import components here */
import { ButtonLink } from '../../components'

const Paragraph = styled.p`
  margin-bottom: 1rem;
`

function BusinessDocumentPartial(): JSX.Element {
  const { t } = useTranslation('common')
  return (
    <>
      <h3>{t('documents.business.title')}</h3>
      <div className="form-group">
        <h4 className="label">{t('vehicles')}</h4>
        <ButtonLink
          className="fullWidth"
          icon={faFilePdf}
          target="_blank"
          to={{
            pathname:
              'https://opus.se/download/18.10d367c317028ab1df43114c/1581433328240/Fullmakt%20registreringsanma%CC%88lan%20160119.pdf',
          }}
        >
          Fullmakt reg.anmälan
        </ButtonLink>
        <ButtonLink
          className="fullWidth"
          icon={faFilePdf}
          target="_blank"
          to={{
            pathname: 'https://opus.se/download/18.9fbf3ac18f959016177f729/1716278076381/Rekvisition.docx',
          }}
        >
          Beställningsblankett
        </ButtonLink>
        <ButtonLink
          className="fullWidth"
          icon={faFilePdf}
          target="_blank"
          to={{
            pathname:
              'https://opus.se/download/18.2a804f7218af973277d9c85/1696488717550/Besiktningsprotokollets%20baksida%20231005.pdf',
          }}
        >
          Protokollets baksida
        </ButtonLink>
        <ButtonLink
          className="fullWidth"
          icon={faFilePdf}
          target="_blank"
          to={{
            pathname:
              'https://opus.se/download/18.10d367c317028ab1df431186/1581433445383/Redogo%CC%88relse%20utfo%CC%88rda%20a%CC%88ndringar%201601.pdf',
          }}
        >
          Redogörelse ändringar
        </ButtonLink>
        <ButtonLink
          className="fullWidth"
          icon={faFilePdf}
          target="_blank"
          to={{
            pathname:
              'https://opus.se/download/18.10d367c317028ab1df43118c/1581433468049/Informationsblad%20och%20blankett%20fo%CC%88r%20skadat%20eller%20saknat%20prima%CC%88rt%20chassinummer.pdf',
          }}
        >
          Fordonets identitet
        </ButtonLink>
      </div>
      <div className="form-group">
        <h4 className="label">{t('finances')}</h4>
        <ButtonLink
          className="fullWidth"
          icon={faLink}
          target="_blank"
          to={{
            pathname: 'https://opus.se/formular/bli-fakturakund',
          }}
        >
          Ansökan om fakturering
        </ButtonLink>
        <ButtonLink
          className="fullWidth"
          icon={faFilePdf}
          target="_blank"
          to={{
            pathname:
              'https://opus.se/download/18.10d367c317028ab1df431187/1581433450568/Autogiroanma%CC%88lan,%20medgivande%20till%20bankgironummer%20150420.pdf',
          }}
        >
          Autogiro-medgivande
        </ButtonLink>
      </div>
      <div className="form-group">
        <h4 className="label">{t('certificates')}</h4>
        <ButtonLink
          className="fullWidth"
          icon={faFilePdf}
          target="_blank"
          to={{
            pathname:
              'https://opus.se/download/18.10d367c317028ab1df431189/1581433459153/Rengo%CC%88rings-%20och%20to%CC%88mningsintyg%20160120.pdf',
          }}
        >
          Rengöringsintyg
        </ButtonLink>
      </div>
    </>
  )
}

function PrivateDocumentPartial() {
  const { t } = useTranslation('common')
  return (
    <>
      <h3>{t('documents.private.title')}</h3>
      <Paragraph>{t('documents.private.text')}</Paragraph>
      <div className="form-group">
        <ButtonLink
          className="fullWidth"
          icon={faLink}
          target="_blank"
          to={{
            pathname: `https://opus.se/besiktningar/blanketter?qs=blanketter`,
          }}
        >
          {t('forms')}
        </ButtonLink>
      </div>
    </>
  )
}

// Component
const DocumentsAsidePartial: React.FunctionComponent = () => {
  const authContext = useContext(AuthenticationContext)
  if (authContext.customerType === 'private') {
    return <PrivateDocumentPartial />
  }
  return <BusinessDocumentPartial />
}

/** @component */
export default DocumentsAsidePartial
