import { tabToEmsp } from '../../utils'

export const information = [
  {
    title: 'Vi har lösningen för just dig och ditt företag!',
    paragraphs: [
      'Med skräddarsydda lösningar som passar just dig och ditt företag förenklar vi ert fordonsägande. Vi ger er säkrare fordon, högre kvalitet, smidigare logistik, bättre fordonsekonomi, förbättrad kundnöjdhet och miljösmarta lösningar.',
      'Kontakta oss på kundportalen@opusbilprovning.se',
      `${tabToEmsp('Mer än kontrollbesiktning!\t\t\t\tVi förenklar logistiken!')}`,
      `${tabToEmsp('- Efterkontroll&emsp;\t\t\t\t\t\t- Hämta och lämna service')}`,
      `${tabToEmsp('- Registreringsbesiktning\t\t\t\t- Garagering')}`,
      `${tabToEmsp('- Enskilt godkännande&emsp;&nbsp;\t\t\t\t- Extra öppethållande')}`,
      `${tabToEmsp('- Besiktning av lyftplattform&nbsp;&nbsp;\t\t\t- Besiktning hos kund')}`,
      `${tabToEmsp('- Besiktning av ADR&nbsp;\t\t\t\t\t- Fasta tider')}`,
      '- Upphandlingskontroll',
      '- Diagnostjänster',
      '- Opus Fordonstester',
      '- Opus Fukttest',
    ],
  },
]

export const servicesPrivate = [
  {
    section: 'Kontrollbesiktning av',
    items: ['Personbil', 'Motorcykel', 'Tunga fordon', 'A-traktor', 'Husvagn & Husbil', 'Släpvagn'],
  },
  {
    section: 'Frivilliga fordonstester',
    items: [
      'E-test',
      'Fordonstester',
      'Gasolkontroll',
      'Opus fukttest',
      'Vägning av Husvagn & Husbil',
      'Veteranfordonstest',
    ],
  },

  'Efterkontroll',
  'Tjänsten Hämta-besikta-lämna',
  'Registreringsbesiktning',
  'Enskilt godkännande',
  'Maskinbesiktning',

  'Läs mer på opus.se',
]
