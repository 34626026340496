import { faCarCrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Sentry from '@sentry/react'
import * as qs from 'querystring'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Route, RouteComponentProps } from 'react-router-dom'
import { isSameMonth, parseISO } from 'date-fns'
import _ from 'lodash'

// Import utilities
import { getEnvironmentVariable } from '../../config'
import {
  WithAuthenticationContext_DEPRECATED,
  WithVehiclesContext_DEPRECATED,
  withAuthenticationContext_DEPRECATED,
  withVehiclesContext_DEPRECATED,
} from '../../contexts'

// Import components
import {
  AsideCard,
  AuthenticatedPage,
  Button,
  Pagination,
  SentryErrorDialog,
  TsInfo,
  VehicleDetailsPartial,
  VehicleFilterForm,
  VehicleFilterFormValues,
  VehicleListing,
} from '../../components'
import { ContentArea, Layout, Sidebar } from '../../components/styled'
import { ContactAsideCard } from '../../containers'

import { UserVehicleInfo, VehicleBasicInfo, api } from 'api'
import {
  StyledDelayedText,
  StyledEmptyVehiclesList,
  StyledLoadingContainer,
  StyledLoadingIndicator,
  StyledLoadingText,
} from './styled'

// Utilities

/** Sorts a list of vehicles by the inspection deadline */
// TODO: `sortVehiclesByInspectionDeadline` används nog inte
// function sortVehiclesByInspectionDeadline(
//   a: CustomerPortalVehicleListItem,
//   b: CustomerPortalVehicleListItem
// ) {
//   return new Date(a.InspectionDeadline!).getTime() - new Date(b.InspectionDeadline!).getTime()
// }

// interface  s

export interface VehiclesPageProps
  extends WithTranslation,
    WithAuthenticationContext_DEPRECATED,
    WithVehiclesContext_DEPRECATED,
    RouteComponentProps {}

export interface VehiclesPageState {
  loading: boolean
  delayed?: string
  pageIndex: number
  vehicles: VehicleBasicInfo[]
  pageSize: number
  filters: VehicleFilterFormValues
  totalVehicles: number
  updatedFilter: boolean
}

// Component

class Vehicles extends React.PureComponent<VehiclesPageProps, VehiclesPageState> {
  public state: VehiclesPageState = {
    filters: {} as VehicleFilterFormValues,
    loading: false,
    pageIndex: 1,
    pageSize: 25,
    totalVehicles: 0,
    updatedFilter: false,
    vehicles: [] as VehicleBasicInfo[],
  }

  public timer = {} as ReturnType<typeof setTimeout>

  public componentDidMount() {
    this.getVehicles()
  }

  public componentDidUpdate(prevProps: VehiclesPageProps, prevState: VehiclesPageState) {
    // If paginated (page index changed), fetch new page with vehicles
    if (this.props.vehiclesContext.fetchedVehiclesError || this.props.vehiclesContext.fetchedBookingsError) {
      return
    }

    // If we have updated vehicles in context, update state.vehicles to the latest changes
    if (this.props.vehiclesContext.fetchedVehicles) {
      const { vehicles } = this.state

      // Get all vehicles from context that are equal to the current ones in state
      const unchangedVehicles = this.props.vehiclesContext.fetchedVehicles.filter(
        contextVehicle => vehicles.findIndex(stateVehicle => stateVehicle === contextVehicle) !== -1,
      )

      // If we have updated vehicles in context but not in state there will be a diff in the length
      if (unchangedVehicles.length !== vehicles.length) {
        // Get the latest vehicles from context to state
        this.getVehicles()
      }
    }

    if (
      prevState.pageIndex !== this.state.pageIndex ||
      prevProps.vehiclesContext.loading !== this.props.vehiclesContext.loading
    ) {
      this.getVehicles()
    }
  }

  public componentWillUnmount() {
    this.clearDelayTimer()

    // Abort waiting for fetch
  }

  public startDelayTimer = (count = 1, timeout = 10000) => {
    this.timer = setTimeout(() => {
      this.setState({
        delayed: this.props.t(`vehicles:loading-delayed-${count}`),
      })

      // Maximum of 4 variations as of now
      if (count < 3 && this.state.loading) {
        this.startDelayTimer(count + 1)
      }
    }, timeout)
  }

  public clearDelayTimer = () => {
    clearTimeout(this.timer)

    this.setState({
      delayed: undefined,
    })
  }

  public getVehicles = async () => {
    try {
      this.setState({
        loading: true,
      })

      const { pageIndex, pageSize } = this.state

      if (
        (!this.props.vehiclesContext.fetchedBookings || !this.props.vehiclesContext.fetchedVehicles) &&
        !this.props.vehiclesContext.loading
      ) {
        this.startDelayTimer()

        await this.props.vehiclesContext.fetchVehiclesAndBookings()

        this.clearDelayTimer()
      }

      const allVehicles = this.props.vehiclesContext.fetchedVehicles || []

      const nextSlice = ((pageIndex - 1) * pageSize) % allVehicles.length

      // Filter vehicles
      const vehicles = _.sortBy(this.filterVehicles(allVehicles), v => v.InspectionDeadline)

      this.setState({
        totalVehicles: vehicles.length,
        vehicles: vehicles.slice(nextSlice, nextSlice + pageSize),
      })

      // document.documentElement.scrollTo(0, 0) // ? Scroll to top on newly fetched vehicles
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  public filterVehicles = (vehicles: VehicleBasicInfo[]) => {
    const { Alias, IsDrivingBan, IsInTraffic, LastInspectionMonth, OrgNo, RegNo, VehicleType } = this.state.filters

    const filteredVehicles: VehicleBasicInfo[] = []
    const lastInspectionMonthDate = LastInspectionMonth ? parseISO(LastInspectionMonth) : undefined

    // TODO: Fix tslint issue
    // tslint:disable-next-line: prefer-for-of
    for (let v = 0; v < vehicles.length; v++) {
      const vehicle = vehicles[v]

      if (this.state.updatedFilter) {
        if (RegNo && vehicle.RegNo.toLowerCase() !== RegNo.toLowerCase().trim()) {
          continue
        }
        if (OrgNo && OrgNo !== vehicle.Owner && OrgNo !== vehicle.IdentityNumber) {
          continue
        }
        if (lastInspectionMonthDate && !isSameMonth(lastInspectionMonthDate, parseISO(vehicle.InspectionDeadline))) {
          continue
        }
        if (IsDrivingBan !== undefined) {
          // TODO: Fix tslint issue
          // tslint:disable-next-line: triple-equals
          if (IsDrivingBan == '1' && !vehicle.HasDrivingBan) {
            continue
            // TODO: Fix tslint issue
            // tslint:disable-next-line: triple-equals
          } else if (IsDrivingBan == '0' && vehicle.HasDrivingBan) {
            continue
          }
        }
        if (IsInTraffic && IsInTraffic !== vehicle.VehicleStatusCode) {
          continue
        }
        if (VehicleType && vehicle.VehicleType && VehicleType !== vehicle.VehicleType.trim()) {
          continue
        }
        if (
          Alias &&
          (!vehicle.Alias ||
            !vehicle.Alias.toLowerCase().replace(/\s/g, '').includes(Alias.toLowerCase().replace(/\s/g, '')))
        ) {
          continue
        }
      }

      filteredVehicles.push(vehicle)
    }

    return filteredVehicles
  }

  public paginate = async ({ pages, toPage }: { pages?: number; toPage?: number }) => {
    const { pathname } = this.props.location

    const newPageIndex = pages && (this.state.pageIndex || 1) + pages

    this.props.history.push({
      pathname,
      search: qs.stringify({
        page: newPageIndex || toPage!,
      }),
    })

    this.setState({
      pageIndex: newPageIndex || toPage!,
    })
  }

  public prevBtnOnClick = () => this.paginate({ pages: -1 })
  public pageBtnOnClick = (toPage: number) => this.paginate({ toPage })
  public nextBtnOnClick = () => this.paginate({ pages: 1 })

  public updateFilters = (values: VehicleFilterFormValues) => {
    this.setState((prevState: VehiclesPageState) => ({
      filters: {
        ...prevState.filters,
        ...values,
      },
      pageIndex: 1,
      updatedFilter: true,
    }))

    this.getVehicles()
  }

  public onBookingCallback = async (RegNo: string, EmailAddress?: string, MobilePhone?: string) => {
    let UserVehicleInfoResponse: UserVehicleInfo = { RegNo }
    if (!EmailAddress || !MobilePhone) {
      const response = await api.vehicles.getUserVehicleInfo(RegNo)
      UserVehicleInfoResponse = response.data || UserVehicleInfoResponse
    }

    const URL = getEnvironmentVariable('BOOK_INSPECTION_URL')
    const Data = {
      EmailAddress: EmailAddress || UserVehicleInfoResponse.VehicleUserEmail || '',
      MobilePhone: MobilePhone || UserVehicleInfoResponse.VehicleUserPhone || '',
      RegNo,
      ReturnURL: window.location.href,
    }

    // eslint-disable-next-line compat/compat
    const res = await fetch(URL, {
      body: JSON.stringify(Data),
      headers: {
        Authorization:
          'Basic ' +
          btoa(
            getEnvironmentVariable('BOOK_INSPECTION_USER') + ':' + getEnvironmentVariable('BOOK_INSPECTION_PASSWORD'),
          ),
        'content-type': 'application/json',
      },
      method: 'POST',
    }).then(data => {
      return data.json()
    })
    window.location = res.SsoURL
  }

  public findBooking = (RegNo: string) => {
    // This function runs on every booking with a complexity of O(n), is it possible to get to O(1) ? Maybe through using a hash-table?
    const item = this.props.vehiclesContext.vehicleBookingsMap.find(vbitem => vbitem.vehicle.RegNo === RegNo)
    return item && item.booking
  }

  public render() {
    const { t, match, authenticationContext } = this.props

    const { fetchedBookingsError, fetchedVehiclesError, loading } = this.props.vehiclesContext

    if (fetchedBookingsError || fetchedVehiclesError) {
      // This is an async-error and therefore we cannot use Error Boundries
      // Create an exception depending on what couldn't be fetched and send it with the Sentry Report
      Sentry.captureException(
        new Error(
          'Could not fetch ' +
            (fetchedBookingsError ? 'bookings' : '') +
            (fetchedBookingsError && fetchedVehiclesError ? ' and ' : '') +
            (fetchedVehiclesError ? 'vehicles' : ''),
        ),
      )

      return (
        <AuthenticatedPage>
          <Layout>
            <Sidebar>
              <SentryErrorDialog />
              <Button
                disabled={loading}
                onClick={
                  // TODO: fix tslint error
                  // tslint:disable-next-line: jsx-no-lambda
                  () => this.getVehicles()
                }
              >
                {t('vehicles:error.try-again')}
              </Button>
            </Sidebar>
          </Layout>
        </AuthenticatedPage>
      )
    }

    return (
      <AuthenticatedPage>
        <Layout>
          <Sidebar>
            <AsideCard>
              <TsInfo />
              <h3>{t('common:filter.filter')}</h3>
              <VehicleFilterForm
                customerType={authenticationContext.customerType}
                OrgNos={authenticationContext.data?.ConnectedOrgNos || undefined}
                submitHandler={this.updateFilters}
                totalVehicles={this.state.totalVehicles}
              />
            </AsideCard>
          </Sidebar>
          <ContentArea style={{ position: 'relative' }}>
            <h1>
              {t('vehicles:page-title')}{' '}
              {
                // If there are vehicles in the list, render the total count
                !!this.state.totalVehicles && (
                  <small style={{ fontSize: '1rem', fontWeight: 500 }}>
                    {t('vehicles:showing-count-vehicles', {
                      count: this.state.totalVehicles,
                    })}
                  </small>
                )
              }
            </h1>
            {/* TODO: Remove this and replace with link to the new booking web
             * Hidden form for redirecting to the booking page
             */}
            {
              // If loading, display loading indicator and status
              this.props.vehiclesContext.loading && (
                <StyledLoadingContainer>
                  <div className="wrapper">
                    <StyledLoadingIndicator />
                    <StyledLoadingText>{t('vehicles:loading')}</StyledLoadingText>
                    {this.state.delayed && <StyledDelayedText>{this.state.delayed}</StyledDelayedText>}
                  </div>
                </StyledLoadingContainer>
              )
            }
            {
              // If there are vehicles in the list
              this.state.vehicles.length ? (
                <>
                  {
                    // Iterate over vehicles list and render listings
                    this.state.vehicles.map(
                      ({ RegNo, InspectionDeadline, Alias, Color, VehicleDescription, YearModel }) => (
                        <VehicleListing
                          key={RegNo}
                          Alias={Alias}
                          Booking={this.findBooking(RegNo)}
                          Color={Color}
                          InspectionDeadline={InspectionDeadline}
                          RegNo={RegNo}
                          VehicleDescription={VehicleDescription}
                          YearModel={YearModel}
                          onBookingClickCallback={this.onBookingCallback}
                        />
                      ),
                    )
                  }
                  <Pagination
                    nextBtnDisabled={this.state.loading}
                    nextBtnOnClick={this.nextBtnOnClick}
                    pageBtnOnClick={this.pageBtnOnClick}
                    pageBufferSize={3}
                    pageIndex={this.state.pageIndex}
                    prevBtnDisabled={this.state.loading}
                    prevBtnOnClick={this.prevBtnOnClick}
                    totalPages={Math.ceil(this.state.totalVehicles / this.state.pageSize)}
                  />
                </>
              ) : (
                // Else show empty list state
                !this.props.vehiclesContext.loading && (
                  <StyledEmptyVehiclesList>
                    <FontAwesomeIcon icon={faCarCrash} />
                    <p>{t('vehicles:no-vehicles-available')}</p>
                  </StyledEmptyVehiclesList>
                )
              )
            }
          </ContentArea>
          <Route
            path={`${match.url}/:RegNo`}
            // TODO: fix tslint error
            // tslint:disable-next-line: jsx-no-lambda
            render={(props: any) => {
              return (
                <VehicleDetailsPartial
                  Booking={this.findBooking((props.match && props.match.params && props.match.params.RegNo) || '')}
                  onBookingClickCallback={this.onBookingCallback}
                  {...props}
                />
              )
            }}
          />
          <Sidebar>
            <ContactAsideCard />
          </Sidebar>
        </Layout>
      </AuthenticatedPage>
    )
  }
}

export const VehiclesPage = withAuthenticationContext_DEPRECATED()(
  withVehiclesContext_DEPRECATED()(withTranslation()(Vehicles)),
)
export default VehiclesPage
