import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorMessage, Field, Form, FormikProps, withFormik } from 'formik'

/* Import utilities here */
import { SetNoteInformation } from 'api'

/* Import components here */
import { StyledTwoColumnLayout } from '../styled'
import { Button, FormErrorMessage, Input } from '../..'

// interface  s
interface NotesFormProps extends SetNoteInformation {
  submitHandler(userNotes: SetNoteInformation): void
}

interface OtherProps {
  aliasLength?: number
}

// Component
export const NotesForm = (props: OtherProps & FormikProps<SetNoteInformation>) => {
  const { isSubmitting, values, initialValues, aliasLength = 10 } = props
  const { t } = useTranslation('vehicles')

  return (
    <Form>
      <StyledTwoColumnLayout>
        <div className="form-group">
          <label htmlFor="Alias">{t('vehicle-alias-label', { maxLength: aliasLength })}</label>
          <Field
            component={Input}
            maxLength={aliasLength}
            name="Alias"
            placeholder={t('vehicle-alias-placeholder')}
            type="text"
          />
          <ErrorMessage component={FormErrorMessage} name="VehicleAlias" />
        </div>
        <div className="form-group">
          <label htmlFor="Notes">{t('notes-label')}</label>
          <Field component="textarea" name="Notes" placeholder={t('notes-placeholder')} rows={4} />
          <ErrorMessage component={FormErrorMessage} name="VehicleUserNotes" />
        </div>
      </StyledTwoColumnLayout>
      {JSON.stringify(values) !== JSON.stringify(initialValues) && (
        <div className="align-right">
          <Button disabled={isSubmitting} type="submit" variant="primary">
            {t('common:save')}
          </Button>
        </div>
      )}
    </Form>
  )
}

export const NotesFormik = React.memo(
  withFormik<NotesFormProps, SetNoteInformation>({
    mapPropsToValues: (props: SetNoteInformation) => ({
      Alias: props.Alias || '',
      Notes: props.Notes || '',
    }),
    handleSubmit: (values: SetNoteInformation, { props, setSubmitting }) => {
      if (props.submitHandler) {
        props.submitHandler(values)
        setSubmitting(false)
      }
    },
    enableReinitialize: true,
  })(NotesForm),
)

/** @component */
export default NotesFormik
