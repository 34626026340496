import * as React from 'react'
import styled from 'styled-components/macro'

const StyledModalBackdrop = styled.div`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  padding: 5rem 0 4rem;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: #0002;

  .container {
    z-index: 6;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 2rem;
    /* padding: 0 4rem; */
  }
`

const StyledModal = styled.section`
  grid-column: 1 / -1;

  padding: 1rem;

  color: ${props => props.theme.colors.black};
  background: white;

  border-radius: ${props => props.theme.borderRadius};

  @media screen and (min-width: ${props => props.theme.sizes.xlContainer}) {
    grid-column: 2 / -2;
  }

  a {
    color: ${props => props.theme.colors.secondary};
  }

  & + & {
    margin-top: 1.5rem;
  }
`

/** A much reusable modal for any type of modal-based interface */
export const Modal: React.FunctionComponent = ({ children, ...props }) => {
  return (
    <StyledModalBackdrop>
      <div className="container">
        <StyledModal role="dialog" {...props}>
          {children}
        </StyledModal>
      </div>
    </StyledModalBackdrop>
  )
}

/** @component */
export default Modal
