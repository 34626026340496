/**
 * Takes a data blob and converts it to a file
 * @param blob A blob containing the data
 * @param fileName Name of the file when saved
 * @param fileExtension The extension of the file, must match the blob file type
 */
export const saveBlobAsFile = (blob: Blob, fileName: string, fileExtension: string): void => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Client is using Internet Explorer 11
    window.navigator.msSaveBlob(blob, `${fileName}.${fileExtension}`)
  } else {
    // Create a URL to make the file downloadable
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.setAttribute('download', `${fileName}.${fileExtension}`)

    // Add the anchor to the DOM
    document.body.appendChild(link)

    // Fake a click so the file starts to download
    link.click()

    // Clean up the DOM by removing the link
    link.remove()
  }
}
