export const VehicleTypeNames: { [key: string]: string } = {
  BUSS: 'Buss',
  LB: 'Lastbil',
  MC: 'Motorcykel',
  MOPED: 'Moped',
  MRED: 'Motorredskap',
  PB: 'Personbil',
  SLÄP: 'Släpvagn',
  TGHJUL: 'Terränghjuling',
  TGSK: 'Terrängskoter',
  TGSL: 'Terrängsläp',
  TGSNÖ: 'Snöskoter',
  TGV: 'Terrängvagn',
  TR: 'Traktor',
}

export enum VehicleStatusCodes {
  inTraffic = 'ITRAFIK',
  unregistered = 'AVREG',
  inactive = 'AVST',
}

export enum ProductCodes {
  'A' = 'Amatörbyggt fordon',
  'A1' = 'Registreringsbesiktning A1',
  'A2' = 'Registreringsbesiktning A2',
  'A2-' = 'Registreringsbesiktning A2-',
  'A2A' = 'Registreringsbesiktning A2A',
  'A2E' = 'A2E Efterkontroll Registreringsbesiktning',
  // 'A2E' = 'Efterkontroll registreringsbesiktnin A2E',
  'A2-E' = 'A2-E Efterkontroll Enkel Registreringbesiktning',
  'A5' = 'Registreringsbesiktning A5',
  // 'AE' = 'AE Efterbesiktning Registreringsbesiktning',
  'AE' = 'Efterkontroll registreringsbesiktning AE',
  'AFS1Test' = 'AFS1Test',
  'ANDRING' = 'Ärbetsprotokoll Ändring',
  'AU' = 'Tillsatsutrustning',
  'B' = 'Kontrollbesiktning',
  'BE' = 'Efterkontroll Kontrollbesiktning',
  'BENK' = 'Kontrollbesiktning, Efterkontroll, Enkel',
  'BILKON' = 'Bilkonsulterna',
  'BRE' = 'Brandskyddskontroll separat',
  'BRÅ' = 'Brandskyddskontroll återkommande',
  'BSÖK1' = 'Besök kund',
  'BSÖK2' = 'Besök kund rabatterad',
  'C' = 'Cardia',
  'CO2E' = 'Intresserad av klimatkompensering?',
  'D1' = 'Yrkesmässig personbefordran',
  'D12' = 'Terrängfordon yrkesmässig trafik för personbefordran',
  'D3' = 'Övningskörningsfordon',
  'D5' = 'Ambulans',
  'D6T' = 'Långgodstransport',
  'D6ÅL' = 'Dispansärende',
  'D6ÅT' = 'Dispansärende',
  'D7' = 'ADR Återkommande',
  'D7D' = 'ADR Lämplighet Dragfordon',
  'D7E' = 'ADR, Efterkontroll, Återkommande besiktning',
  'D7S' = 'ADR Lämplighet Släp utan tank/skåp',
  'D8' = 'Lämplighet ADR, Första besiktning',
  'D8-' = 'Lämplighet ADR, Första besiktning, Typgodkänt chassi',
  'D8E' = 'ADR, Efterkontroll, Första besiktning',
  'DEK' = 'E-diagnos Hälsokoll',
  'DEKS' = 'E-diagnos Säkerhet&Miljö, kombination',
  'DEN' = 'E-diagnos Komplett',
  'DENS' = 'E-diagnos, Säkerhet&Miljö',
  'DIAGAR' = 'Bildiagnos Garantibil',
  'DIAPB' = 'Opus Biltest (rabatt då elektronisk felavläsning utgår)',
  'EBE' = 'Efterbesiktning Extra bromskontroll',
  'EBK' = 'Extra bromskontroll',
  'eD1P' = 'e-Diagnos, Ett delsystem, Privatkund',
  'EK' = 'Delprov',
  'ENS' = 'ENS buss 0-3500 kg',
  // 'ENS' = 'Enskilt Godkännande, bromsat släp 0-3500 kg',
  // 'ENS' = 'Enskilt Godkännande, buss 3501-kg',
  // 'ENS' = 'Enskilt Godkännande, lastbil 0-3500 kg',
  // 'ENS' = 'Enskilt Godkännande, lastbil 10001-kg',
  // 'ENS' = 'Enskilt Godkännande, lastbil 3501-10000 kg',
  // 'ENS' = 'Enskilt Godkännande, obromsat släp, 0-3500 kg',
  // 'ENS' = 'Enskilt Godkännande, personbil',
  // 'ENS' = 'Enskilt Godkännande, släp 3501 - kg',
  'FB' = 'Frivillig besiktning',
  'FBES' = '1:a besiktning',
  'FLE' = 'Efterkontroll Flygande inspektion',
  'FM' = 'Frivillig miljökontroll',
  'FOSK B' = 'FOSK Buss B',
  // 'FOSK B' = 'FOSK PB B',
  'FOSK BE' = 'FOSK Buss BE',
  // 'FOSK BE' = 'FOSK PB BE',
  'FTG' = 'Opus Biltest Företagsanpassad',
  'G' = 'Gasolkontroll',
  'GSL' = 'Gröna och Säkra Lastbilar med Släp',
  'HVT' = 'Opus Husvagnstest',
  'I' = 'Kran Pristillägg extra vipparm (jib) vid LÅ LE',
  'IBL' = 'IBC 2,5års',
  'IBS' = 'IBS 5årsC',
  'K' = 'Ändring av fordonsslag',
  'KAAG' = 'Autoexperten ackreditering grundavgift 60 min',
  'KAAT' = 'Autoexperten ackreditering tillägg 30 min',
  'KAMUX3' = 'KAMUX',
  'KSLP' = 'Kontrollerad Skadeverkstad, Lackprocess Intern',
  'KSLX' = 'Kontrollerad Skadeverkstad, Lackprocess Extern',
  'KSPP' = 'Kontrollerad Skadeverkstad, Plastprocess',
  'KSVP' = 'Kontrollerad Skadeverkstad, Processgenomgång',
  'KSVT' = 'Kontrollerad Skadeverkstad, Teknisk kontroll',
  'LAS' = 'Armkran Spårgående >= 5 ton',
  // 'LAS' = 'Armkran Spårgående < 5 ton',
  'LAÅ' = 'Kran, Besiktning arbetskorg, Återkommande',
  'LD' = 'Derrikran >= 5ton',
  // 'LD' = 'Derrikran < 5ton',
  'LE' = 'Efterbesiktning Kranbesiktning',
  'LHBT' = 'Opus Husbilstest 0-3500 kg',
  'LM' = 'Lyft & Kran LM Montage',
  'LMK' = 'Mastkran',
  'LMP' = 'Mobilplattform',
  'LP' = 'Pelararmkran',
  'LPK' = 'Portalkran >= 5ton',
  // 'LPK' = 'Portalkran < 5ton',
  'LPS' = 'Pelarsvängkran',
  'LT' = 'Telfer >= 5ton',
  // 'LT' = 'Telfer < 5ton',
  'LTK' = 'Tornkran',
  'LTM' = 'Tonkran mobil',
  'LTR' = 'Traverskran >= 5ton',
  // 'LTR' = 'Traverskran < 5ton',
  'LV' = 'Besiktning, vinsch',
  'LYP' = 'Besiktning av lyftplattform',
  'LYPE' = 'Efterbesiktning Lyft',
  'LÅ' = 'Kranbesiktning',
  'MCT' = 'Opus MC test',
  'MO' = 'Montagebesiktning',
  'MP' = 'Registreringsbesiktning MP',
  'MPE' = 'MPE Efterbesiktning Mopedbesiktning',
  'O' = 'Ombyggt fordon',
  'OBT' = 'Opus Bromstest',
  'OMT' = 'OPUS Miljötest',
  'PROK' = 'Stickprov skadeverkstad (KBV-Plåt&Lack)',
  'PROV' = 'Stickprov, Bilverkstad',
  'PRVW' = 'Kontrollerad Bilverkstad, Stickprov, VW',
  'PTÅ' = 'Besiktning personkorg, Tillfällig återkommande',
  'RB' = 'Revissionsbesiktning',
  'RESA' = 'Resa, Kontrollerad Bilverkstad, Kilometerkostnad',
  'RESKS' = 'Resa, Kvalitets- och skadekontroller, Restid',
  'RESKV' = 'Resa, Kvalitetsuppföljning Bilverkstad, Kilometerkostnad',
  'RETI' = 'Restid, Kontrollerad Bilverkstad',
  'SANTLEAF' = 'Santander Återlämningstest Nissan Leaf',
  'SANTTEST' = 'Santander Återlämningstest',
  'SKAD' = 'Fördjupad Skadereparationskontroll',
  'SKADO' = 'Okulär Skaderaparationskontroll',
  'SVWP' = 'SVW Personbil',
  // 'T' = 'Trafiksäkerhets- och miljökontroll',
  'T' = 'Trafiksäkerhets- och miljökontroll (T)',
  // 'T' = 'Trafiksäkerhetskontroll',
  'TE' = 'Efterkontroll Trafiksäkerhets- och miljökontroll',
  // 'TE' = 'Efterkontroll trafiksäkerhetskontroll',
  'TEST' = 'Testartikel',
  'TESTBK' = 'Test Bilkonsulterna',
  'THBT' = 'Opus Husbilstest 10001 - 16000 kg',
  // 'THBT' = 'Opus Husbilstest 16001 - kg',
  // 'THBT' = 'Opus Husbilstest 3501 - 10000 kg',
  'TS' = 'Opus Inspektion och fotografering',
  'TÄT' = 'Opus Fukttest Husbil',
  // 'TÄT' = 'Opus Fukttest Husbil + gasol',
  // 'TÄT' = 'Opus Fukttest Husvagn',
  'TÄTG' = 'Opus Fukttest husvagn + Gasol',
  'V' = 'Vägning',
  'W' = 'WAYKE',
  'VE' = 'Enkel vägning',
  'VT' = 'Veteranfordonstest',
  'ÅG' = 'Grävmaskin',
  'ÅH' = 'Höj och sänkbar hytt',
  'ÅMA' = 'Mobil arbetsplattform',
  'ÅMG' = 'Minigrävmaskin (4,5 ton)',
  'ÅMK' = 'Mobilkran',
  'ÅML' = 'Maskin med kranarm',
  'ÅPG' = 'Pallgafflar',
  'ÅPM' = 'Pålmaskin',
  'ÅRT' = 'Runtomsvängande teleskoptruck',
  'ÅT' = 'Teleskoptruck',
}

export function GetVehicleStatusCode(VehicleStatusCode?: string): boolean | null {
  let vehicleStatusCode: null | boolean = null

  switch (VehicleStatusCode && VehicleStatusCode.trim()) {
    case VehicleStatusCodes.inTraffic: {
      vehicleStatusCode = true
      break
    }
    case VehicleStatusCodes.inactive:
    case VehicleStatusCodes.unregistered: {
      vehicleStatusCode = false
      break
    }
  }

  return vehicleStatusCode
}
