import * as React from 'react'
import styled from 'styled-components/macro'

export const ContentArea = React.memo(styled.section`
  grid-column: span 1;
  grid-row: 1;

  @media screen and (min-width: ${props => props.theme.sizes.xlContainer}) {
    grid-column: 2 / span 2;
    grid-row: unset;
  }
`)

export default ContentArea
