import styled from 'styled-components/macro'

import { LoadingIndicator } from '../../components/styled'
import { Card } from '../../components/Card'

export const StyledEmptyVehiclesList = styled(Card)`
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;

  svg {
    margin-bottom: 1rem;

    font-size: 4rem;
  }
`

export const StyledLoadingContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 4rem);

  position: fixed;
  left: 0;
  top: 4rem; /* Position below the header */

  background: ${props => props.theme.colors.secondary + 'dd'};

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 50%;
    left: 50%;

    text-align: center;

    transform: translate(-50%, -50%);
  }
`

export const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: 5rem;

  padding: 1rem;

  font-size: 3rem;

  background: white;
  color: ${props => props.theme.colors.primary};

  border-radius: 5rem;
  border-width: 0;
`

export const StyledLoadingText = styled.p`
  margin-top: 1em;

  font-size: 1.25rem;
  font-weight: 500;
`

export const StyledDelayedText = styled.p`
  width: 90vw;
  max-width: 18rem;

  padding: 1rem;

  font-weight: 500;
  text-align: left;

  background: white;
  color: ${props => props.theme.colors.primary};

  border-radius: 0.5rem;
`
