import { DefaultTheme, ThemedCssFunction, css } from 'styled-components/macro'

export const breakpoints = {
  xs: '30rem', // 480px
  sm: '48rem', // 768px
  md: '62rem', // 992px
  lg: '75rem', // 1200px
  xl: '83rem', // 1328px
} as const

type TResponseive = { [key in keyof typeof breakpoints]: ThemedCssFunction<DefaultTheme> }

export const responsiveUp = Object.keys(breakpoints).reduce((acc, label) => {
  return {
    ...acc,
    [label]: (arg0, ...args) => css`
      @media screen and (min-width: ${breakpoints[label]}) {
        ${css(arg0, ...args)};
      }
    `,
  }
}, {}) as TResponseive

export const responsiveDown = Object.keys(breakpoints).reduce((acc, label) => {
  return {
    ...acc,
    [label]: (arg0, ...args) => css`
      @media screen and (max-width: ${breakpoints[label]}) {
        ${css(arg0, ...args)};
      }
    `,
  }
}, {}) as TResponseive
