import styled, { css } from 'styled-components/macro'

import { Button } from 'components'
import { Card } from 'components/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { responsiveUp } from 'theme/breakpoints'

export const PageHeader = styled.h2`
  margin-bottom: 2rem;
`

export const PageSubHeader = styled.h3`
  font-weight: 400;
`

export const BackgroundCard = styled(Card)`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 2rem;

  ${responsiveUp.xs`
    flex-direction: row;
  `}
`

export const LoginTypeIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;

  @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
    font-size: 10rem;
  }
`

export const LoginTypeButton = styled(Button)`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 0;
  align-items: center;
  justify-content: center;
  height: auto;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}

  h2 {
    color: white;
  }
`

export const LoginTypeBusiness = styled(LoginTypeButton)`
  margin-right: 0 !important;

  h2 {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export const ErrorText = styled.p`
  margin-top: 0;
  white-space: normal;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
    flex-direction: row;
  }
`

export const FooterContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  h2 {
    margin-top: 2rem;
  }
`
