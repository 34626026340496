import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthenticationContext, VehiclesContext } from '../../contexts'

import { AuthenticatedPage, Card, NewsCard } from '../../components'
import { TipsPartial } from 'components/TipsPartial'
import { StartPageLinks } from 'components/StartPageLinks'
import { ContentArea, Layout, Sidebar } from '../../components/styled'
import { ContactAsideCard } from '../../containers'

import { contentPrivate, newsBusiness } from '../../data/start'

export function StartPage(): JSX.Element {
  const { t } = useTranslation()
  const vehiclesContext = useContext(VehiclesContext)
  const authContext = useContext(AuthenticationContext)
  const news = authContext.customerType === 'private' ? contentPrivate : newsBusiness

  useEffect(() => {
    if (!vehiclesContext.loading && !vehiclesContext.fetchedVehicles) {
      vehiclesContext.fetchVehiclesAndBookings()
    }
  }, [vehiclesContext])

  return (
    <AuthenticatedPage>
      <Layout>
        <Sidebar>
          <StartPageLinks />
          <Card>
            <TipsPartial />
          </Card>
        </Sidebar>
        <ContentArea>
          <h1>{t('start:page-title')}</h1>
          {news.map((newsItem, idx) => (
            <NewsCard key={idx} paragraphs={newsItem.paragraphs} title={newsItem.title} />
          ))}
        </ContentArea>
        <Sidebar>
          <ContactAsideCard />
        </Sidebar>
      </Layout>
    </AuthenticatedPage>
  )
}

export default StartPage
