import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { AxiosError } from 'axios'

import { Page } from 'components/layout'
import { ContentArea, Layout } from 'components/styled'
import { ButtonLink, LoginForm } from 'components'
import { StyledHelpLink, StyledHelpSection } from './BusinessLoginPage.styles'

import { api } from 'api'
import { AuthenticationContext } from 'contexts'
import { Routes } from 'config'
import { BusinessLoginPageProps } from './BusinessLoginPage.interfaces'
import { clearCustomerChoice } from 'utils'

export const BusinessLoginPage: React.FC<BusinessLoginPageProps> = () => {
  const authenticationContext = useContext(AuthenticationContext)
  const { t } = useTranslation('login')
  const { search } = useLocation()
  const [errorMessage, setErrorMessage] = useState<string>('')

  async function postLogin(email: string, password: string): Promise<void> {
    setErrorMessage('')

    try {
      const res = await api.login({ Email: email, Password: password })

      if (res.data) {
        authenticationContext.login(res.data)
      } else {
        console.error('Something went wrong!')
      }
    } catch (error) {
      setErrorMessage((error as AxiosError).response?.statusText || error.message)
    }
  }

  if (authenticationContext.isAuthenticated) {
    // eslint-disable-next-line compat/compat
    const callback = new URLSearchParams(search).get('callback') // TODO:  Kontrollera detta noga så att callbacks fungerar vid redirect. Använd rätt parameternamn.
    return <Redirect to={(callback as string) || Routes.Start} />
  }

  return (
    <Page hideNav portalType="company">
      <Layout>
        <ContentArea>
          <h1>{t('log-in')}</h1>
          <LoginForm
            errorMessage={errorMessage}
            extraButton={
              <ButtonLink noArrowIcon to={Routes.Root} variant="accent" onClick={clearCustomerChoice}>
                {t('back-to-start')}
              </ButtonLink>
            }
            onSubmit={postLogin}
          />
          <StyledHelpSection>
            <h2>{t('help')}</h2>
            <StyledHelpLink>
              <Link to={Routes.ResetPassword}>{t('forgotten-password')}</Link>
            </StyledHelpLink>
            <StyledHelpLink>{t('no-account')}</StyledHelpLink>
          </StyledHelpSection>
        </ContentArea>
      </Layout>
    </Page>
  )
}
