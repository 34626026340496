// use for IE only
import '@babel/polyfill' // TODO: Remove this, it has been deprecated and replaced by `core-js` and ´regenerator-runtime`.
import 'core-js/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import './ie11.css'

import 'config/sentry'
import { App } from './app'
import { LogConfig } from './config/'
import * as serviceWorker from './serviceWorker'

// Activate Reactotron locally if it is enabled in your `.env`-file.
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_REACTOTRON === 'true') {
  LogConfig.configure(true)
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
