import * as React from 'react'
import styled from 'styled-components/macro'

import { ErrorMessage, Field, Form, FormikProps } from 'formik'
import { WithTranslation, withTranslation } from 'react-i18next'

// Import utilities
import { api } from 'api'
import { TrafficStatus } from 'data/vehicles'
import { getLastDayInMonthByDate, isOtherYear, saveBlobAsFile, toOrgNoFormat } from 'utils'
import { VehicleStatusCodes, VehicleTypeNames } from '../VehicleDetails/VehicleDetails.constants'
import { VehicleTypes } from 'utils'

// Import components
import { faFileExcel } from '@fortawesome/pro-solid-svg-icons'
import { Button, FilterSection, FormErrorMessage, Input } from '..'
import { StyledMonth } from './VehicleFilterForm.styles'
import { TCustomerType } from 'contexts'

// interface
export interface VehicleFilterFormValues {
  OrgNo?: string
  IsDrivingBan?: string
  IsInTraffic?: TrafficStatus | string
  LastInspectionMonth?: string
  RegNo?: string
  VehicleType?: string
  Alias?: string
}

export interface OtherProps {
  /**
   * If the current logged in user is a private and not business user
   */
  customerType: TCustomerType | undefined

  OrgNos?: string[]

  totalVehicles: number
}

export interface InnerVehicleFilterFormProps
  extends OtherProps,
    WithTranslation,
    FormikProps<VehicleFilterFormValues> {}

interface InnerVehicleFilterFormState {
  edited: boolean
  valuesLastSubmit?: VehicleFilterFormValues
}

// Styles
const FilterBox = styled.div`
  .action-bar {
    display: flex;
    justify-content: space-between;

    margin-top: 1rem;
  }
  button.fullWidth {
    margin-top: 1rem;
  }
`

const cleanNumber = (orgNo: string): number => parseInt(orgNo.replace(/[^0-9]/, '')) || 0

// Component
export class InnerVehicleFilterForm extends React.Component<InnerVehicleFilterFormProps, InnerVehicleFilterFormState> {
  public static YesNoUndefinedValues = {
    Yes: '1',
    No: '0',
    Undefined: '-1',
  }

  public static YesNoUndefinedToBoolean = (value: string | undefined): boolean | undefined =>
    value === InnerVehicleFilterForm.YesNoUndefinedValues.Yes
      ? true
      : value === InnerVehicleFilterForm.YesNoUndefinedValues.No
      ? false
      : undefined
  constructor(props: InnerVehicleFilterFormProps) {
    super(props)

    this.state = {
      edited: false,
      valuesLastSubmit: props.values,
    }
  }

  /**
   * Create an object containing the filters used to create the Excel file.
   */
  public getActiveFilters = () => {
    // Get the filter values from the form
    const { Alias, RegNo, OrgNo, IsDrivingBan, IsInTraffic, LastInspectionMonth, VehicleType } = this.props.values

    // Create a new object wich defaults to undefined instead of empty string and 'true'/'false'
    const params = {
      Alias: Alias || undefined,
      IsDrivingBan: InnerVehicleFilterForm.YesNoUndefinedToBoolean(IsDrivingBan),
      // IsInTraffic: InnerVehicleFilterForm.YesNoUndefinedToBoolean(IsInTraffic as string),
      IsInTraffic:
        IsInTraffic === VehicleStatusCodes.inTraffic
          ? true
          : IsInTraffic === VehicleStatusCodes.inactive
          ? false
          : undefined,
      LastInspectionMonth: LastInspectionMonth || undefined,
      OrgNo: OrgNo || undefined,
      RegNo: RegNo || undefined,
      Page: 0,
      PageSize: this.props.totalVehicles,
      VehicleType: VehicleType || undefined,
      // PageSize: this.props.totalVehicles,
      // FIXME: Should VehicleType be available to use as a filter?
      // VehicleType
    }

    return params
  }

  /**
   * Download vehicle data from API and save it as a file
   */
  public saveExcelFile = async () => {
    try {
      const fp = this.getActiveFilters()
      const { data } = await api.vehicles.getCsvVehiclesWithFilter(
        fp.Page,
        fp.PageSize,
        fp.RegNo,
        fp.OrgNo,
        fp.IsDrivingBan,
        fp.IsInTraffic,
        fp.LastInspectionMonth,
        fp.Alias,
        fp.VehicleType,
        true,
        {
          responseType: 'blob',
        },
      )

      const { type } = data

      // Create a file blob from the response
      const blob = new Blob([data], { type })
      // FIXME: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Access-Control-Expose-Headers, when this is added to the back-end we can get the file name dynamically instead of hard coding it here.
      saveBlobAsFile(blob, 'Opus Bilprovning fordon', 'xlsx')
    } catch (thrown) {
      console.error(thrown)
    }
  }

  public formChanged = () => {
    // TODO: Compare current values with valuesLastSubmit and set edit to values !== valuesLastSubmit
    this.setState({ edited: true })
  }

  public formSubmitted = () => {
    this.setState({ edited: false, valuesLastSubmit: this.props.values })
  }

  public formReset = () => {
    this.setState({ edited: false, valuesLastSubmit: this.props.values })
    this.props.handleSubmit()
  }

  public render() {
    const { values, OrgNos, customerType, totalVehicles, t } = this.props

    const sortedOrgNos = OrgNos?.sort((a, b) => cleanNumber(a) - cleanNumber(b))

    return (
      <FilterBox>
        <Form onChangeCapture={this.formChanged} onResetCapture={this.formReset} onSubmitCapture={this.formSubmitted}>
          {customerType === 'company' && (
            <FilterSection label={t('vehicles:organisation-number')} value={values.OrgNo}>
              <Field component="select" name="OrgNo">
                <option value="">{t('vehicles:filter.orgno')}</option>
                {sortedOrgNos &&
                  sortedOrgNos.map((option, idx) => (
                    <option key={idx} label={toOrgNoFormat(option)} value={option}>
                      {option}
                    </option>
                  ))}
              </Field>
            </FilterSection>
          )}
          <FilterSection label={t('vehicles:driving-ban')} value={values.IsDrivingBan}>
            <Field component="select" name="IsDrivingBan">
              <option value={InnerVehicleFilterForm.YesNoUndefinedValues.Undefined}>
                {t('vehicles:filter.is-driving-ban')}
              </option>
              <option value={InnerVehicleFilterForm.YesNoUndefinedValues.Yes}>{t('common:yes')}</option>
              <option value={InnerVehicleFilterForm.YesNoUndefinedValues.No}>{t('common:no')}</option>
            </Field>
          </FilterSection>
          <FilterSection label={t('vehicles:last-inspection-month')} value={values.LastInspectionMonth}>
            <Field component="select" name="LastInspectionMonth">
              <option label={t('vehicles:filter.last-inspection-month')} value="">
                {t('vehicles:filter.last-inspection-month')}
              </option>

              {
                // eslint-disable-next-line compat/compat
                Array.from({
                  length: 14,
                  /* ! This should be dynamically fetched */
                }).map((_, idx) => {
                  const date = getLastDayInMonthByDate(new Date(), idx)
                  const monthLabel = new Date(date).toLocaleDateString('sv-SE', {
                    month: 'long',
                    year: isOtherYear(date) ? 'numeric' : undefined,
                  })
                  return (
                    <StyledMonth key={date} label={monthLabel} value={date}>
                      {monthLabel}
                    </StyledMonth>
                  )
                })
              }
            </Field>
          </FilterSection>
          <FilterSection
            label={t('vehicles:traffic-status')}
            value={values.IsInTraffic !== TrafficStatus.unset && values.IsInTraffic}
          >
            <Field component="select" name="IsInTraffic">
              <option label={t('vehicles:filter.is-in-traffic')} value="">
                {t('vehicles:filter.is-in-traffic')}
              </option>
              <option label={t('vehicles:in-traffic')} value={VehicleStatusCodes.inTraffic}>
                {t('vehicles:in-traffic')}
              </option>
              <option label={t('vehicles:not-in-traffic')} value={VehicleStatusCodes.inactive}>
                {t('vehicles:not-in-traffic')}
              </option>
            </Field>
          </FilterSection>
          <FilterSection label={t('vehicles:registration-number')}>
            <Field component={Input} name="RegNo" placeholder={t('vehicles:filter.regno')} />
            <ErrorMessage component={FormErrorMessage} name="RegNo" />
          </FilterSection>
          <FilterSection label={t('vehicles:vehicle-type')} value={values.VehicleType}>
            <Field component="select" name="VehicleType">
              <option label={t('vehicles:filter.vehicle-type')} value="">
                {t('vehicles:filter.vehicle-type')}
              </option>
              {
                // eslint-disable-next-line compat/compat
                Array.from({
                  length: Object.keys(VehicleTypeNames).length,
                }).map((_, idx) => {
                  const vehicleType = VehicleTypeNames[Object.keys(VehicleTypeNames)[idx]]
                  return (
                    <option key={idx} label={vehicleType} value={VehicleTypes[vehicleType]}>
                      {vehicleType}
                    </option>
                  )
                })
              }
            </Field>
          </FilterSection>
          {customerType === 'company' && (
            <FilterSection label={t('vehicles:vehicle-alias')} value={values.Alias}>
              <Field component={Input} name="Alias" placeholder={t('vehicles:filter.alias')} />
            </FilterSection>
          )}
          {customerType === 'company' && (
            <Button
              className="fullWidth"
              disabled={totalVehicles === 0 || !!this.state.edited}
              iconLeft={faFileExcel}
              role="button"
              type="button"
              onClick={this.saveExcelFile}
            >
              {t('vehicles:filter.export-to-excel')}
            </Button>
          )}
          <div className="action-bar">
            <Button type="reset" variant="danger">
              {t('common:filter.reset')}
            </Button>
            <Button type="submit" variant="primary">
              {t('common:filter.filter')}
            </Button>
          </div>
        </Form>
      </FilterBox>
    )
  }
}

export default withTranslation()(InnerVehicleFilterForm)
