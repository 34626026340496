import * as React from 'react'
import styled from 'styled-components/macro'

export const Layout = React.memo(styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  @media screen and (min-width: ${props => props.theme.sizes.xlContainer}) {
    grid-template-columns: repeat(4, 1fr);
  }
`)

export default Layout
