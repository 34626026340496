import * as React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTire } from '@fortawesome/pro-regular-svg-icons'

const StyledLoader = styled.div`
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  font-size: 2rem;

  color: white;

  border: 0.125rem solid ${props => props.theme.colors.primary};

  animation: in 0.1s ease-in-out;
`

export const LoadingIndicator = (props: any) => (
  <StyledLoader {...props}>
    <FontAwesomeIcon spin icon={faTire} />
  </StyledLoader>
)
