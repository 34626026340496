import i18next from 'i18next'
import { DetectorOptions, default as LngDetector } from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { getEnvironmentVariable } from 'config'

// English
import en_app from 'i18n/en/app.json'
import en_common from 'i18n/en/common.json'
import en_error from 'i18n/en/error.json'
import en_login from 'i18n/en/login.json'
import en_profile from 'i18n/en/profile.json'
import en_registration from 'i18n/en/registration.json'
import en_reset_password from 'i18n/en/reset-password.json'
import en_services from 'i18n/en/services.json'
import en_settings from 'i18n/en/settings.json'
import en_start from 'i18n/en/start.json'
import en_vehicles from 'i18n/en/vehicles.json'

// Swedish
import sv_app from 'i18n/sv/app.json'
import sv_common from 'i18n/sv/common.json'
import sv_error from 'i18n/sv/error.json'
import sv_login from 'i18n/sv/login.json'
import sv_profile from 'i18n/sv/profile.json'
import sv_registration from 'i18n/sv/registration.json'
import sv_reset_password from 'i18n/sv/reset-password.json'
import sv_services from 'i18n/sv/services.json'
import sv_settings from 'i18n/sv/settings.json'
import sv_start from 'i18n/sv/start.json'
import sv_vehicles from 'i18n/sv/vehicles.json'

const options: DetectorOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'navigator', 'htmlTag'],

  // keys or params to lookup language from
  lookupLocalStorage: 'i18nextLng',

  // cache user language on
  caches: ['localStorage'],

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
}

i18next
  .use(initReactI18next)
  .use(LngDetector)
  .init(
    {
      interpolation: { escapeValue: false },
      fallbackLng: ['sv', 'en'],
      lng: 'sv',
      debug: getEnvironmentVariable('I18NEXT_DEBUG'),
      defaultNS: 'common',
      detection: options,
      resources: {
        sv: {
          app: sv_app,
          common: sv_common,
          error: sv_error,
          login: sv_login,
          profile: sv_profile,
          registration: sv_registration,
          services: sv_services,
          settings: sv_settings,
          start: sv_start,
          vehicles: sv_vehicles,
          'reset-password': sv_reset_password,
        },
        en: {
          app: en_app,
          common: en_common,
          error: en_error,
          login: en_login,
          profile: en_profile,
          registration: en_registration,
          services: en_services,
          settings: en_settings,
          start: en_start,
          vehicles: en_vehicles,
          'reset-password': en_reset_password,
        },
      },
      react: {
        wait: true,
      },
    },
    err => {
      if (err) {
        return console.log('something went wrong loading', err)
      }
    },
  )

export default i18next
