import * as React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components/macro'
import classNames from 'classnames'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StyledButtonLink = styled(Link)`
  display: inline-block;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  text-overflow: ellipsis;
  overflow: hidden;

  padding: 1rem 1.25rem;

  font: inherit;
  font-weight: 600;
  line-height: 1;

  /* background: ${props => `${props.theme.colors.secondary}08`}; */
  color: ${props => props.theme.colors.secondary};
  background: #ebf7f6;

  border: 1px solid transparent;
  border-radius: ${props => props.theme.borderRadius};

  transition: background 0.1s cubic-bezier(0, 0, 0.2, 1), color 0.1s cubic-bezier(0, 0, 0.2, 1),
    border-color 0.1s cubic-bezier(0, 0, 0.2, 1);

  &.withIcon {
    padding-left: 1rem;
  }

  .icon {
    margin-right: 0.75rem;
  }

  .arrow {
    margin-left: 0.25rem;

    &.back {
      margin-left: 0;
      margin-right: 0.25rem;
    }
  }

  small {
    font-size: 0.75rem;
  }

  :hover {
    background: #d9f0ee;
  }

  &.fullWidth {
    width: 100%;
  }

  :not(.fullWidth) {
    margin-right: 0.5rem;
  }

  &.fullWidth + &.fullWidth {
    margin-top: 0.5rem;
  }

  :last-child {
    margin-right: 0;
  }

  &.accent {
    background: ${props => props.theme.colors.accent};
    color: white;

    :hover {
      background: ${props => props.theme.colors.accentThin};
    }
  }

  &.primary {
    color: white;
    background: ${props => props.theme.colors.secondary};

    :hover {
      background: ${props => props.theme.colors.secondaryLight};
    }

    &[disabled] {
      color: ${props => props.theme.colors.primaryLight};
      background: ${props => props.theme.colors.primaryDark};
    }
  }

  &.secondary {
    background: white;
    border-color: ${props => props.theme.colors.secondary};

    :hover {
      color: white;
      background: ${props => props.theme.colors.secondary};
      border-color: ${props => props.theme.colors.secondary};
    }
  }
`

const Text = styled.span<{ alignLeft?: boolean }>`
  flex-grow: 1;
  ${({ alignLeft }) => !alignLeft && 'text-align: center;'}
`

// FIXME: any is bad (have fun!)
export interface ButtonLinkProps extends LinkProps<any> {
  /** The route path to navigate to */
  // to: string
  /** A FontAwesome IconProp to optionally render in front of the button text */
  icon?: IconProp
  noArrowIcon?: boolean
  className?: string
  variant?: 'default' | 'plain' | 'primary' | 'secondary' | 'accent' | 'danger'
  back?: boolean
  alignLeft?: boolean
}

/** A link which looks like a button, but with an arrow indicating redirection */
export const ButtonLink: React.FunctionComponent<ButtonLinkProps & React.ButtonHTMLAttributes<unknown>> = ({
  to,
  icon,
  noArrowIcon,
  className,
  variant,
  back,
  children,
  alignLeft,
  ...props
}) => {
  return (
    <StyledButtonLink className={classNames('btn', className, variant, icon && 'withIcon')} to={to} {...props}>
      {!noArrowIcon && back && (
        <small className="arrow back">
          <FontAwesomeIcon icon={faChevronLeft} />
        </small>
      )}
      {icon && <FontAwesomeIcon className="icon" icon={icon} />}
      <Text alignLeft={alignLeft}>{children}</Text>
      {!noArrowIcon && !back && (
        <small className="arrow">
          <FontAwesomeIcon icon={faChevronRight} />
        </small>
      )}
    </StyledButtonLink>
  )
}

/** @component */
export default ButtonLink
