import {
  IconDefinition,
  faBus,
  faCar,
  faMotorcycle,
  faQuestionCircle,
  faSnowmobile,
  faTractor,
  faTruckMoving,
  faTruckPlow,
  faTruckRamp,
} from '@fortawesome/pro-solid-svg-icons'

export const VehicleTypes: { [key: string]: string } = {
  Buss: 'BUSS',
  Lastbil: 'LB',
  Moped: 'MOPED',
  Motorcykel: 'MC',
  Motorredskap: 'MRED',
  Personbil: 'PB',
  Släpvagn: 'SLÄP',
  Snöskoter: 'TGSNÖ',
  Terränghjuling: 'TGHJUL',
  Terrängskoter: 'TGSK',
  Terrängsläp: 'TGSL',
  Terrängvagn: 'TGV',
  Traktor: 'TR',
}

export function GetVehicleTypeIcon(VehicleType?: string): IconDefinition {
  let vehicleTypeIcon: null | IconDefinition = null

  switch (VehicleType && VehicleType.trim()) {
    case VehicleTypes.Personbil: {
      vehicleTypeIcon = faCar
      break
    }
    case VehicleTypes.Buss: {
      vehicleTypeIcon = faBus
      break
    }
    case VehicleTypes.Lastbil: {
      vehicleTypeIcon = faTruckMoving
      break
    }
    case VehicleTypes.Traktor: {
      vehicleTypeIcon = faTractor
      break
    }
    case VehicleTypes.Moped:
    case VehicleTypes.Motorcykel: {
      vehicleTypeIcon = faMotorcycle
      break
    }
    case VehicleTypes.Terrängsläp:
    case VehicleTypes.Släpvagn: {
      vehicleTypeIcon = faTruckRamp
      break
    }
    case VehicleTypes.Snöskoter:
    case VehicleTypes.Terrängskoter: {
      vehicleTypeIcon = faSnowmobile
      break
    }
    case VehicleTypes.Motorredskap: {
      vehicleTypeIcon = faTruckPlow
      break
    }
    case VehicleTypes.Terränghjuling:
    case VehicleTypes.Terrängvagn:
    default: {
      vehicleTypeIcon = faQuestionCircle
      break
    }
  }

  return vehicleTypeIcon
}
