import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import { responsiveUp } from 'theme/breakpoints'

import { Button } from 'components/Button'

const ChatStartContainer = styled.div`
  position: fixed;

  flex-diretion: column;
  display: flex;
  width: 100%;
  right: 0;
  bottom: 0;

  ${responsiveUp.sm`
  width: auto;
    z-index: 100;
    display: block;
    right: 2rem;
    bottom: 2rem;
  `}
`

const ChatStartButton = styled(Button)`
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`

const ChatButtonText = styled.span`
  display: none;
  ${responsiveUp.xs`
    display: inline;
  `}
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const $: any

export function PuzzelChat() {
  const { t } = useTranslation()
  const ref = useRef(null)
  useEffect(() => {
    if ($ === undefined) return

    $(ref.current).intelecomChat({
      /*
          mandatory parameters
          */
      customerKey: '400061',
      queueKey: 'q_chat_opus_bokning',
      showStarter: false,
      /*
          non-mandatory parameters
          */
      timeId: '22777_time8', //check opening hours
      timeOpenExits: ['open'],
      visualQueueId: 38570, //checks queue,closes if no agents are logged on
      // onChatStart: function () {}, //runs when clicking the chat button
      // onChatWindowShow: function () {}, //runs when chat window is displayed
      useTracker: true, // Turn tracking on/off for site. Default “true” (true|false)
      showForm: true, //enable user to enter name and e-mail at startup
      inputFields: [
        {
          inputLabel: 'Namn*',
          inputVariableName: 'Namn',
          inputRequired: true,
          inputPlaceholder: 'Förnamn Efternamn',
        },
        {
          inputLabel: 'E-mail',
          inputVariableName: 'email',
          inputType: 'email',
          inputPlaceholder: 'E-mail',
        },
        {
          inputLabel: 'Fråga*',
          inputVariableName: 'Fråga',
          inputType: 'text',
          inputRequired: true,
          inputPlaceholder: 'Fråga',
        },
        {
          inputLabel: 'Registreringsnummer',
          inputVariableName: 'Registreringsnummer',
          inputPlaceholder: 'ABC123',
        },
      ],
      verticalStarterPosition: 'top', // position the chat starter circle
      resizable: false, //enables user to resize window
      languageCode: 'SE', //language settings (EN, NO, SE, DK, FI, HU, BG)
      showSendEmail: false, //allows to show email option
      enableSurvey: true,
      surveyOnlyWhenConnected: true,
      surveyMaxScore: 5, //higest rating option
      surveyMinScore: 1, //lowest rating option
      surveyType: 'star', //rating type, star og radio
      msgSurveyHeader: 'Nöjdhetsundersökning', //Survey header text
      msgSurveyQuestion: 'Hur tycker du att handläggaren hjälpte dig med detta ärende?', //Survey question text
    })
  }, [])

  return (
    <>
      <div ref={ref}></div>
      <ChatStartContainer>
        <ChatStartButton className="chatstarter">
          <FontAwesomeIcon icon={faComments} size="2x" />
          <ChatButtonText>&nbsp;&nbsp;{t('common:help')}</ChatButtonText>
        </ChatStartButton>
      </ChatStartContainer>
    </>
  )
}
