import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { getEnvironmentVariable } from 'config/environment'
import packageJson from '../../package.json'

const debug = getEnvironmentVariable('SENTRY_DEBUG', false)
const dsn = getEnvironmentVariable('SENTRY_DSN')
const enabled = getEnvironmentVariable('SENTRY_ENABLED', false)
const environment = getEnvironmentVariable('SENTRY_ENVIRONMENT')
const release = `${packageJson.name}@${packageJson.version}`

const sentryInitSettings: Sentry.BrowserOptions = {
  attachStacktrace: true,
  debug,
  dsn,
  enabled,
  environment,
  integrations: [new Integrations.BrowserTracing()],
  release,
  tracesSampleRate: 1.0,
}

Sentry.init(sentryInitSettings)
