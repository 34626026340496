import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import * as Yup from 'yup'
import { ErrorMessage, Field, Form, FormikProps, withFormik } from 'formik'

/* Import utilities here */
import i18n from '../../config/i18n'
import { Routes } from 'config'
import { api } from 'api'

/* Import components here */
import { Button, ButtonLink, FormErrorMessage, Input } from '..'

/* interface  s */

interface FormValues {
  companyName: string
  orgNo: string
  name: string
  email: string
  formResponseError?: string | Record<string, unknown>
}

interface OtherProps {
  formResponseError?: string | Record<string, unknown> | null
}

type IResetPasswordFormProps = OtherProps

/* CSS */

const StyledFormGroup = styled.div`
  margin-bottom: 2rem;
`

const StyledActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`

/* Validation */

const ResetPasswordSchema = Yup.object().shape({
  companyName: Yup.string().required(i18n.t('reset-password:company-name-required')),
  orgNo: Yup.string()
    .min(10, i18n.t('reset-password:orgno-length'))
    .max(15, i18n.t('reset-password:orgno-length'))
    .required(i18n.t('reset-password:orgno-required')),
  name: Yup.string().required(i18n.t('reset-password:name-required')),
  email: Yup.string().email(i18n.t('login:email-valid')).required(i18n.t('login:email-required')),
})

/* Form */

const InnerForm = (props: OtherProps & FormikProps<FormValues>) => {
  const { touched, errors, isSubmitting, status } = props
  const { t } = useTranslation('reset-password')

  return (
    <Form className="login-form">
      {status ? (
        <>
          <p style={{ fontSize: '1.5rem' }}>{t('reset-password:success')}</p>
          <ButtonLink noArrowIcon style={{ marginTop: '2rem' }} to={Routes.Root} variant="primary">
            {t(`reset-password:back`)}
          </ButtonLink>
        </>
      ) : (
        <>
          {(errors.formResponseError || props.formResponseError) && (
            <FormErrorMessage>{errors.formResponseError || props.formResponseError}</FormErrorMessage>
          )}
          <StyledFormGroup>
            <label htmlFor="companyName">{t('company-name')}</label>
            <Field
              component={Input}
              name="companyName"
              placeholder={t('reset-password:company-name-placeholder')}
              type="text"
              variant={errors.companyName && touched.companyName ? 'danger' : 'plain'}
            />
            <ErrorMessage component={FormErrorMessage} name="companyName" />
          </StyledFormGroup>
          <StyledFormGroup>
            <label htmlFor="orgNo">{t('orgno')}</label>
            <Field
              component={Input}
              name="orgNo"
              placeholder={t('reset-password:orgno-placeholder')}
              type="text"
              variant={errors.orgNo && touched.orgNo ? 'danger' : 'plain'}
            />
            <ErrorMessage component={FormErrorMessage} name="orgNo" />
          </StyledFormGroup>
          <StyledFormGroup>
            <label htmlFor="name">{t('name')}</label>
            <Field
              component={Input}
              name="name"
              placeholder={t('reset-password:name-placeholder')}
              type="text"
              variant={errors.name && touched.name ? 'danger' : 'plain'}
            />
            <ErrorMessage component={FormErrorMessage} name="name" />
          </StyledFormGroup>
          <StyledFormGroup>
            <label htmlFor="email">{t('login:email')}</label>
            <Field
              component={Input}
              name="email"
              placeholder={t('login:enter-email')}
              type="email"
              variant={errors.email && touched.email ? 'danger' : 'plain'}
            />
            <ErrorMessage component={FormErrorMessage} name="email" />
          </StyledFormGroup>
          <StyledActionBar>
            <ButtonLink back to={Routes.BusinessLogin}>
              {t('reset-password:cancel')}
            </ButtonLink>
            <Button disabled={!!Object.keys(errors).length || isSubmitting} type="submit" variant="primary">
              {t('reset-password:submit')}
            </Button>
          </StyledActionBar>
        </>
      )}
    </Form>
  )
}

/* Formik */

export const ResetPasswordForm = withFormik<IResetPasswordFormProps, FormValues>({
  mapPropsToValues: (values: IResetPasswordFormProps) => {
    return {
      companyName: '',
      orgNo: '',
      name: '',
      email: '',
      formResponseError: values.formResponseError || '',
    }
  },
  validationSchema: ResetPasswordSchema,
  handleSubmit: async (values, { setSubmitting, setErrors, setStatus }) => {
    try {
      const { data: ok } = await api.accounts.requestNewPassword({
        CompanyName: values.companyName,
        CompanyOrgNo: values.orgNo,
        Email: values.email,
        Name: values.name,
      })

      if (ok) {
        setStatus({
          ok,
        })
      } else {
        throw new Error('Something went wrong when resetting password')
      }
    } catch (err) {
      console.error(err)

      setErrors({
        formResponseError: err,
      })
    } finally {
      setSubmitting(false)
    }
  },
  isInitialValid: false,
})(InnerForm)

/** @component */
export default ResetPasswordForm
