import { captureException } from '@sentry/react'

const CustomerChoiceKey = 'customer-choice'
export type TCustomerChoice = 'company' | 'private'

/**
 * Sets the choice the customer has made in localStorage
 *
 * @param customerChoice The customer's choice
 *
 * @returns True if successfull, false otherwise
 */
export const setCustomerChoice = (customerChoice: TCustomerChoice): boolean => {
  try {
    localStorage.setItem(CustomerChoiceKey, customerChoice)
    return true
  } catch (e) {
    captureException(e)
  }
  return false
}

export const getCustomerChoice = (): TCustomerChoice | undefined => {
  try {
    const customerChoice = localStorage.getItem(CustomerChoiceKey) as TCustomerChoice | undefined

    if (customerChoice) return customerChoice
  } catch (e) {
    captureException(e)
  }
  return undefined
}

/**
 * Removes the customer's previous choice (if any) from localStorage
 *
 * @returns True if successfull, false otherwise
 */
export const clearCustomerChoice = (): boolean => {
  try {
    localStorage.removeItem(CustomerChoiceKey)
    return true
  } catch (e) {
    captureException(e)
  }
  return false
}
