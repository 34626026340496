import * as React from 'react'
import * as Sentry from '@sentry/react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { Button } from '..'

const StyledSentryErrorDialog = styled.div`
  padding: 1em;
  padding-left: 0;
`

// Component
/**
 * This component assumes that the exception has already been registered with Sentry
 * and just shows the UI required to send a User Feedback Report.
 */
export const SentryErrorDialog: React.SFC = () => {
  const { t } = useTranslation('error')
  return (
    <StyledSentryErrorDialog>
      <h3>{t('crash.header')}</h3>
      <p>{t('crash.message')}</p>
      <Button onClick={() => Sentry.showReportDialog()}>{t('crash.feedback-button')}</Button>
    </StyledSentryErrorDialog>
  )
}

/** @component */
export default SentryErrorDialog
