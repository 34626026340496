import * as React from 'react'
import styled from 'styled-components/macro'

const StyledCard = styled.section`
  padding: 1rem;

  color: ${props => props.theme.colors.black};
  background: white;

  border-radius: ${props => props.theme.borderRadius};

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.colors.primaryDark};
  }

  a {
    color: ${props => props.theme.colors.secondary};
  }

  & + & {
    margin-top: 1.5rem;
  }
`

/** A much reusable card for any type of card-based interface */
export const Card: React.FunctionComponent<{ className?: string }> = ({ children, ...props }) => {
  return <StyledCard {...props}>{children}</StyledCard>
}

/** @component */
export default Card
