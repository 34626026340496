import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorMessage, Field, Form, FormikProps, withFormik } from 'formik'

/* Import utilities here */
import { SetContactInformation } from 'api'
import { validationSchema } from './ContactInformationForm.validation'

/* Import components here */
import { Button, FormErrorMessage, Input } from '../../'
import { StyledTwoColumnLayout } from '../'

// interfaces
interface ContactInformationFormProps extends SetContactInformation {
  submitHandler?(values: SetContactInformation): void
}

// Component
export const ContactInformationForm = (props: FormikProps<SetContactInformation>) => {
  const { isSubmitting, errors, initialValues, values } = props
  const { t } = useTranslation('common')

  return (
    <Form>
      <StyledTwoColumnLayout>
        <div className="form-group">
          <label htmlFor="Name">{t('name')}</label>
          <Field
            component={Input}
            name="Name"
            placeholder={`${t('filter.enter')} ${t('name').toLowerCase()}`}
            type="text"
          />
          <ErrorMessage component={FormErrorMessage} name="Name" />
        </div>
        <div className="form-group">
          <label htmlFor="Phone">{t('phone-number')}</label>
          <Field
            component={Input}
            name="Phone"
            placeholder={`${t('filter.enter')} ${t('phone-number').toLowerCase()}`}
            type="tel"
          />
          {errors.Phone && <FormErrorMessage>{t('validation.phone-format')}</FormErrorMessage>}
        </div>
        <div className="form-group">
          <label htmlFor="Email">{t('email-address')}</label>
          <Field
            component={Input}
            name="Email"
            placeholder={`${t('filter.enter')} ${t('email-address').toLowerCase()}`}
            type="email"
          />
          {errors.Email && <FormErrorMessage>{t('validation.email-format')}</FormErrorMessage>}
        </div>
      </StyledTwoColumnLayout>
      {JSON.stringify(values) !== JSON.stringify(initialValues) && (
        <div className="align-right">
          <Button disabled={isSubmitting} type="submit" variant="primary">
            {t('save')}
          </Button>
        </div>
      )}
    </Form>
  )
}

export const ContactInformationFormik = withFormik<ContactInformationFormProps, SetContactInformation>({
  mapPropsToValues: (props: SetContactInformation) => ({
    Name: props.Name || '',
    Phone: props.Phone || '',
    Email: props.Email || '',
  }),
  handleSubmit: (values: SetContactInformation, { props, setSubmitting }) => {
    if (props.submitHandler) {
      props.submitHandler(values)
      setSubmitting(false)
    }
  },
  enableReinitialize: true,
  validationSchema,
})(ContactInformationForm)

/** @component */
export default ContactInformationFormik
