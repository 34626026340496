import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

// Import components
import { Page } from 'components/layout'
import { ContentArea, Layout } from 'components/styled'
import { ButtonLink } from 'components'

import { Routes } from 'config'

// Component
const Error: React.FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation('error')

  return (
    <Page hideNav>
      <Layout>
        <ContentArea>
          <h1>{t('page.not-found')}</h1>
          <p>{t('page.not-found-text')}</p>
          <br />
          <ButtonLink back to={Routes.Root} variant="primary">
            {t('go-home')}
          </ButtonLink>
        </ContentArea>
      </Layout>
    </Page>
  )
}

export const ErrorPage = Error
