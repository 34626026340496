export const newsBusiness = [
  {
    title: 'Vi gör det enklare för dig och ditt företag! Viktig information!',
    paragraphs: [
      '20 maj 2018 börjar nya besiktningsregler gälla. Den största förändringen är att den fasta besiktningsperioden på fem månader, som baseras på den sista siffran i registreringsrumret, slopas.',
      'Kundportalen är uppdaterad med de nya inställelsereglerna för samtliga fordon så att du som företagskund kan känna dig trygg med att ha koll på dina fordon i vår portal.',
      'För mer information, gå till vår hemsida www.opus.se',
      'Med Kundportalen ska ni enkelt kunna ha kontroll på fordonsflottan och ha alla fordonsuppgifter samlade på ett ställe. I Fordonsöversiktens övre högra hörn kan ni se när fordonsdatan senast uppdaterades (uppdateringen sker ungefär var 7:e–10:e dag).',
      'Fordonen listas lättöverskådligt med registreringsnummer, fordonstyp och modell. Status gällande om fordonet är på- eller avställt samt eventuellt körförbud och besiktningsperioden för nästkommande besiktning redovisas. Ni ser även tidpunkten för den senaste besiktningen.',
      'Om ni klickar på ett fordon går det bland annat att se uppgifter såsom företaget det är registrerat på, vem som brukar fordonet (fylls i manuellt av er), totalvikt, om fordonet har dragkrok eller annan kopplingsanordning och utfallet från det senaste besiktningsprotokollet. Ni kan även fylla i egna noteringar och skapa personliga fordonsalias för att lättare kunna identifiera fordonen internt. Med filtreringsfunktionen går det att söka fordon baserat på alias, fordon som har körförbud, trafikstatus (på-/avställt), sista besiktningsmånad med mera.',
    ],
  },
  {
    title: 'NYHET! Besiktningsprotokoll direkt i portalen',
    paragraphs: [
      'Nu får du som användare av Opus Kundportal enkel tillgång till dina fordons besiktningsprotokoll. Till en början finns endast besiktningsprotokoll, utfärdade av Opus Bilprovning, efter 2017-03-11 tillgängliga i portalen.',
      'Vi jobbar ständigt med att utveckla och förbättra portalen!',
      'För att kunna göra portalen ännu bättre och förenkla ert fordonsägande ytterligare behöver vi veta vad just ni behöver.',
      'Maila era behov och förslag till oss på kundportalen@opusbilprovning.se',
    ],
  },
]

export const contentPrivate = [
  {
    title: 'Vi gör det enkelt för dig',
    paragraphs: [
      `<img src="https://opus.se/images/18.2444c60016ea68fd6051d0d4/1575374357391/opus-test-img-z1.png" style="max-width: 100%;" />`,
      'Här på Mina sidor håller du enkelt koll på dina fordon. Alla fordonsuppgifter är samlade på ett ställe för att göra ditt fordonsägande så smidigt som möjligt. Här kan du också välja att få din nästa inbjudan via SMS eller e-post.',
      'På fliken ”Fordonsöversikt” får du en bra översikt över dina fordon och de listas lättöverskådligt med registreringsnummer, fordonstyp och modell. Även status gällande om fordonet är på- eller avställt samt eventuellt körförbud. Fordonsdatan uppdateras var 7:e dag. På ”Fordonsöversikt” kan du även se besiktningsperioden för nästkommande besiktning, tidpunkten för senaste besiktning samt ta del av besiktningsprotokoll.',
      `På fliken ”Fler Tjänster” ser du alla olika besiktningar och tester som Opus gör - vi är ju numera helhetsleverantör av fordonsbesiktning i Sverige. På denna sida hittar du också blanketter och dokument och får många bra tips och råd om ditt fordonsägande.`,
      `<b>Kontakta oss:</b><br/>Vi arbetar hela tiden med att utveckla och förbättra kundportalen Mina sidor för att göra den ännu bättre - och förenkla ditt fordonsägande ytterligare. Därför tar vi tacksamt emot dina förslag. Maila till oss på marknad@opusbilprovning.se`,
    ],
  },
]
