import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

/* Import utilities here */
import { WithVehiclesContext_DEPRECATED, withVehiclesContext_DEPRECATED } from '../../contexts'

import {
  FutureBookingInfo,
  SetContactInformation,
  SetNoteInformation,
  SetNotificationInformation,
  UserVehicleInfo,
  VehicleBasicInfo,
  api,
} from 'api'

/* Import components here */
import { VehicleDetails } from '../'

// interface  s
interface RouteParams {
  RegNo: string
}

export interface VehicleDetailsPartialProps extends WithVehiclesContext_DEPRECATED, RouteComponentProps<RouteParams> {
  Booking?: FutureBookingInfo
  onBookingClickCallback: (RegNo: string, EmailAddress?: string, MobilePhone?: string) => void
}

export interface VehicleDetailsPartialState {
  loading: boolean
  vehicle?: VehicleBasicInfo
  userVehicleInfo?: UserVehicleInfo
}

// Component
class VehicleDetailsPartial extends React.PureComponent<VehicleDetailsPartialProps, VehicleDetailsPartialState> {
  state = {
    loading: false,
    vehicle: undefined,
    userVehicleInfo: undefined,
  }

  componentDidMount() {
    this.getVehicleAndUserVehicleInfo()
  }

  /**
   * Get complete vehicle information from two endpoints.
   */
  getVehicleAndUserVehicleInfo = async () => {
    try {
      this.setState({
        loading: true,
      })

      await this.getVehicle()

      await this.getUserVehicleInfo()
    } catch (thrown) {
      console.error(thrown)
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  /**
   * Get vehicle information from context. If no context exists then get information from API.
   */
  getVehicle = async () => {
    const { RegNo } = this.props.match.params

    const vehicle = this.props.vehiclesContext.fetchedVehicles
      ? this.props.vehiclesContext.fetchedVehicles.find(vehicle => vehicle.RegNo === RegNo)
      : undefined

    // Vehicle exists in context. This only happens when browser has downloaded all vehicles from API.
    if (vehicle) {
      this.setState({
        vehicle,
      })
    } else {
      // I.e. the user navigates directly to a vehicle through the URL, then no context exists yet.
      // We need to get the data for just that vehicle. In the background the rest of the vehicles are downloaded.
      const { data: vehicle } = await api.vehicles.getVehicle(RegNo)

      if (vehicle) {
        this.setState({
          vehicle,
        })
      } else {
        throw new Error('Could not fetch vehicle in VehicleDetailsPartial')
      }
    }
  }
  /**
   * Get the user vehicle information of a selected vehicle
   */
  getUserVehicleInfo = async () => {
    const { data: userVehicleInfo } = await api.vehicles.getUserVehicleInfo(
      (this.state.vehicle! as VehicleBasicInfo).RegNo,
    )

    // Update component state with fresh data from API.
    if (userVehicleInfo) {
      this.setState({
        userVehicleInfo,
      })

      // This data doesn't originally exist in the context since we never get it when fetching all vehicles. Let's update VechiclesContext so it appears to get faster next time a vehicle is opened.
      this.props.vehiclesContext.updateUserVehicleInfo(userVehicleInfo)
    }
  }

  /**
   * Save contact information on a vehicle
   */
  submitContactInformation = async (contactInfo: SetContactInformation) => {
    try {
      this.setState({
        loading: true,
      })

      const { data: userVehicleInfo } = await api.vehicles.setContactInfo(
        (this.state.vehicle! as VehicleBasicInfo).RegNo,
        contactInfo,
      )

      // Update component state with fresh data from API.
      if (userVehicleInfo) {
        this.setState({
          userVehicleInfo,
        })

        // Since we have a cached copy of all vehicle data in the context this must also be updated. If not we don't update the context the filtering will not work properly.
        this.props.vehiclesContext.updateUserVehicleInfo(userVehicleInfo)
      }
    } catch (thrown) {
      console.error(thrown)
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  /**
   * Update the notification settings of a vehicle
   */
  submitNotificationSettings = async (notificationSettings: SetNotificationInformation) => {
    try {
      this.setState({
        loading: true,
      })

      const { data: userVehicleInfo } = await api.vehicles.setNotifications(
        (this.state.vehicle! as VehicleBasicInfo).RegNo,
        notificationSettings,
      )

      if (userVehicleInfo) {
        this.setState({
          userVehicleInfo,
        })

        // Since we have a cached copy of all vehicle data in the context this must also be updated. If not we don't update the context the filtering will not work properly.
        this.props.vehiclesContext.updateUserVehicleInfo(userVehicleInfo)
      }
    } catch (thrown) {
      console.error(thrown)
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  /**
   * Save user notes of a vehicle
   */
  submitUserNotes = async (userNotes: SetNoteInformation) => {
    try {
      this.setState({
        loading: true,
      })

      // Send changes to API
      const { data: userVehicleInfo } = await api.vehicles.setNotes(
        (this.state.vehicle! as VehicleBasicInfo).RegNo,
        userNotes,
      )

      // Update component state with latest data from API after successfully saving changes
      if (userVehicleInfo) {
        this.setState({
          userVehicleInfo,
        })

        // Since we have a cached copy of all vehicle data in the context this must also be updated. If not we don't update the context the filtering will not work properly.
        this.props.vehiclesContext.updateUserVehicleInfo(userVehicleInfo)
      }
    } catch (thrown) {
      console.error(thrown)
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  render() {
    const { onBookingClickCallback } = this.props

    return this.state.vehicle ? (
      <VehicleDetails
        {...this.state.vehicle!}
        {...this.state.userVehicleInfo}
        Booking={this.props.Booking}
        submitContactInformation={this.submitContactInformation}
        submitNotificationSettings={this.submitNotificationSettings}
        submitUserNotes={this.submitUserNotes}
        vehicleUserNotes={this.getUserVehicleInfo}
        onBookingClickCallback={onBookingClickCallback}
      />
    ) : null
  }
}

/** @component */
export default withVehiclesContext_DEPRECATED()(withRouter(VehicleDetailsPartial))
