import { api } from 'api'
import { useQuery } from 'react-query'

export const initiateBankIdLogin = async () => {
  const response = await api.accounts.initiateBankIdLogin()

  return response.data
}
export const useInitiateBankIdLogin = (enabled = true) =>
  useQuery('initiateBankIdSesion', initiateBankIdLogin, { refetchOnWindowFocus: false, enabled })
