import i18n from '../config/i18n'
import moment from 'moment'

/**
 *
 * @param dateISOString
 * @param offsetMonths
 */
export function getLastDayInMonthByDate(date: Date, offsetMonths = 0): string {
  return moment(new Date(date.getUTCFullYear(), date.getUTCMonth() + 1 + offsetMonths, 0)).format('YYYY-MM-DDTHH:mm:ss')
}

/**
 *
 * @param dateISOString
 */
export function isOtherYear(dateISOString: string): boolean {
  const currentYear = new Date().getUTCFullYear()
  const dateYear = new Date(dateISOString).getUTCFullYear()

  return currentYear !== dateYear
}

/**
 * Returns a date in the YYYY-MM-dd format
 * @param dateISOString
 */
export function toDateString(dateISOString: string): string | null {
  if (!dateISOString) {
    return null
  }

  return new Date(dateISOString).toLocaleDateString('sv-SE')
}

/**
 * Returns a date in the date format of the given locale with year, short month and day
 * @param dateISOString
 * @param locale Ex. 'sv-SE' or 'en-US'
 */
export function toLocaleDateString(dateISOString: string, locale: string = i18n.language): string | null {
  if (!dateISOString) {
    return null
  }

  return new Date(dateISOString).toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}
