import * as React from 'react'
import styled from 'styled-components/macro'
import classNames from 'classnames'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StyledButton = styled.button`
  cursor: pointer;
  display: inline-block;

  height: 3rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  padding: 1rem 1.25rem;

  font: inherit;
  font-weight: 600;
  line-height: 1;

  color: ${props => props.theme.colors.secondary};
  background: #ebf7f6;
  border: 1px solid transparent;
  border-radius: ${props => props.theme.borderRadius};

  transition: background 0.1s cubic-bezier(0, 0, 0.2, 1), color 0.1s cubic-bezier(0, 0, 0.2, 1),
    border-color 0.1s cubic-bezier(0, 0, 0.2, 1);

  small {
    font-size: 0.75rem;
  }

  :hover {
    background: ${props => props.theme.colors.secondaryThinLight};
  }

  :last-child {
    margin-right: 0;
  }

  &.withIcon {
    padding-left: 1rem;
  }

  .icon {
    &.left {
      margin-right: 0.75rem;
    }

    &.right {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.fullWidth + &.fullWidth {
    margin-top: 0.5rem;
  }

  &.narrow {
    height: 2.5rem;

    padding: 0.75rem 1rem;
  }

  &.default {
    &[disabled] {
      color: ${props => props.theme.colors.primaryDark};
      background: ${props => props.theme.colors.lightGray};
    }
  }

  &.plain {
    color: ${props => props.theme.colors.primary};
  }

  &.accent {
    background: ${props => props.theme.colors.accent};
    color: white;

    :hover {
      background: ${props => props.theme.colors.accentFocused};
    }

    &[disabled] {
      color: ${props => props.theme.colors.primaryDark};
      background: ${props => props.theme.colors.lightGray};
    }
  }

  &.primary {
    color: white;
    background: ${props => props.theme.colors.secondary};

    :hover {
      background: ${props => props.theme.colors.secondaryLight};
    }

    &[disabled] {
      color: ${props => props.theme.colors.primaryLight};
      background: ${props => props.theme.colors.primaryDark};
    }
  }

  &.secondary {
    background: white;
    border-color: ${props => props.theme.colors.secondary};

    :hover {
    }
  }

  &.danger {
    color: ${props => props.theme.colors.red};
    background: #d0021b20;
    border-color: transparent;
  }
`

export interface ButtonProps {
  /** A FontAwesome IconProp to optionally render in front of the button text */
  iconLeft?: IconProp
  iconRight?: IconProp
  iconRightSmall?: boolean
  variant?: 'default' | 'plain' | 'primary' | 'secondary' | 'accent' | 'danger'
  narrow?: boolean
  className?: string
}

// There is a bug when wrapping a Memo component directly https://github.com/yannickcr/eslint-plugin-react/issues/2105
const ButtonComponent: React.FunctionComponent<ButtonProps & React.ButtonHTMLAttributes<unknown>> = ({
  iconLeft,
  iconRight,
  iconRightSmall,
  className,
  variant = 'default',
  narrow,
  children,
  ...props
}) => (
  <StyledButton className={classNames(className, variant, narrow && 'narrow', iconLeft && 'withIcon')} {...props}>
    {iconLeft && <FontAwesomeIcon className="icon left" icon={iconLeft} />}
    {children}
    {iconRight &&
      (iconRightSmall ? (
        <small className="icon right">
          <FontAwesomeIcon icon={iconRight} />
        </small>
      ) : (
        <div className="icon right">
          <FontAwesomeIcon icon={iconRight} />
        </div>
      ))}
  </StyledButton>
)

const Button = React.memo(ButtonComponent, (prev, next) => {
  if (prev.variant !== next.variant || prev.disabled !== next.disabled || prev.children !== next.children) {
    return false
  }

  return true
})

/**
 * Form Button
 */
export { Button }

/** @component */
export default Button
