import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthenticationContext } from 'contexts'

import { AsideCard } from '../../components'

const BusinessContact = function BusinessContact() {
  const { t } = useTranslation('common')
  return (
    <AsideCard title={t('contact')}>
      <div className="form-group">
        <h4 className="label">{t('phone')}</h4>
        <p>
          <a href={`tel:${t('app:contact.support-phone-raw')}`}>{t('app:contact.support-phone')}</a>
        </p>
      </div>
      <div className="form-group">
        <h4 className="label">{t('email')}</h4>
        <p>
          <a href={`mailto:${t('app:contact.support-email-raw')}`}>{t('app:contact.support-email')}</a>
        </p>
      </div>
    </AsideCard>
  )
}

const PrivateContact = function PrivateContact() {
  const { t } = useTranslation('common')
  return (
    <AsideCard title={`Har du frågor? Kontakta oss!`}>
      <div className="form-group">
        <h4 className="label">{t('phone')}</h4>
        <p>
          <a href={`tel:${t('app:contact.private.support-phone-raw')}`}>{t('app:contact.private.support-phone')}</a>
        </p>
      </div>
      <div className="form-group">
        <h4 className="label">Bokning och kundtjänst</h4>
        <p>
          Måndag - fredag: 07.00-18.00
          <br />
          Lördag - söndag: 08.00-16.00
          <br />
        </p>
        <p>
          <a href={`mailto:kundtjanst@opusbilprovning.se`}>kundtjanst@opusbilprovning.se</a>
        </p>
      </div>
      <div className="form-group">
        <h4 className="label">Tekniska frågor</h4>
        <p> Mejla, chatta eller ring till vår kundtjänst om du vill bli uppringd av en besiktningsingenjör. </p>
        <p>
          Måndag - fredag: 07.00-16.00
          <br />
          Lördag: stängt
          <br />
          Söndag och helgdag: stängt
          <br />
        </p>
        <p>
          <a href={`mailto:kundtjanst@opusbilprovning.se`}>kundtjanst@opusbilprovning.se</a>
        </p>
      </div>
      <div className="form-group">
        <h4 className="label">Registreringsbesiktning</h4>
        <p>
          Måndag - torsdag: 08.00-18.00
          <br />
          Fredag: 08.00-17.00
          <br />
          Lördag: stängt
          <br />
          Söndag och helgdag: stängt
        </p>
        <p>
          <a href={`mailto:kundtjanst@opusbilprovning.se`}>kundtjanst@opusbilprovning.se</a>
        </p>
      </div>
      <div className="form-group">
        <h4 className="label">Skyddade personuppgifter</h4>
        <p>Har du skyddade personuppgifter, ring till vår kundtjänst så hjälper vi dig att boka en tid.</p>
      </div>
    </AsideCard>
  )
}

export const ContactAsideCard: React.FunctionComponent = () => {
  const authContext = useContext(AuthenticationContext)
  if (authContext.customerType === 'private') {
    return <PrivateContact />
  } else {
    return <BusinessContact />
  }
}

/** @component */
export default React.memo(ContactAsideCard)
