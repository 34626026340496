import * as React from 'react'
import styled from 'styled-components/macro'

import { Modal } from '../../components/Modal'

export const StyledVehicleDetails = styled(Modal)`
  line-height: 1;

  .meta-header {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
      flex-direction: row;
    }

    section {
      display: flex;
      justify-content: space-between;

      @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
        flex-direction: column;
      }

      .last-inspection-date {
        order: 2;

        font-weight: 500;

        @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
          order: 1;

          margin-top: 0;
          margin-bottom: 0.5rem;
        }
      }

      .regno {
        @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
          order: 2;
        }
      }
    }

    footer {
      display: flex;
      flex: 1;

      align-items: center;
      justify-content: space-between;

      margin-top: 1rem;

      @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
        justify-content: flex-end;

        margin: 0;
      }
    }
  }

  .past-due {
    color: ${props => props.theme.colors.red};
  }

  .details {
    display: flex;
    flex-direction: column;

    h3 {
      color: ${props => props.theme.colors.primary};
    }

    section {
      margin: 2rem 0;
    }

    .align-right {
      text-align: right;
    }
  }
`

export const StyledTwoColumnLayout = React.memo(styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: ${(props: { rowGap?: boolean }) => (props.rowGap ? '1rem' : 0)};

  .form-group:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
    grid-template-columns: 1fr 1fr;

    .form-group:nth-child(2n-1):nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  ol,
  ul {
    padding-right: 0;
  }
`)
