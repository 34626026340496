import * as React from 'react'
import { useTranslation } from 'react-i18next'

/* Import utilities here */
import { toLocaleDateString } from '../../utils'
import { WithAuthenticationContext_DEPRECATED, withAuthenticationContext_DEPRECATED } from '../../contexts'

/* Import components here */
import { AsideCard } from '../../components'

// Props
export type IAboutAsideCardProps = WithAuthenticationContext_DEPRECATED

// Component
const AboutAsideCard: React.FunctionComponent<IAboutAsideCardProps> = ({ authenticationContext, ...props }) => {
  const { t } = useTranslation('common')

  return (
    <AsideCard title={`${t('about')} ${t('app:name')}`} {...props}>
      {authenticationContext.data && (
        <p>
          {t('services:data-last-fetched-from-ts')}:{' '}
          {authenticationContext.data.LatestInfofromTs &&
            toLocaleDateString(authenticationContext.data.LatestInfofromTs)}
        </p>
      )}
    </AsideCard>
  )
}

/** @component */
export default React.memo(withAuthenticationContext_DEPRECATED()(AboutAsideCard))
