import * as React from 'react'

import { Card } from '../../components/Card'

export interface AsideCardProps {
  /** The title to display as a heading */
  title?: string
}

/** A specific type of card to be used as an aside, with not as prominent content */
export const AsideCard: React.FunctionComponent<AsideCardProps> = ({ title, children }) => {
  return (
    <Card>
      {title && <h3>{title}</h3>}
      {children}
    </Card>
  )
}

/** @component */
export default AsideCard
