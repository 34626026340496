// Add APP_DATA to the global Window object interface
type TAppData = {
  API_URL: string
  I18NEXT_DEBUG: boolean
  SENTRY_DEBUG: boolean
  SENTRY_DSN: string
  SENTRY_ENABLED: boolean
  SENTRY_ENVIRONMENT: string
  USE_MOCKUP_DATA: boolean
  USE_REACTOTRON: boolean
  COOKIE_POLICY_URL: string
}
declare global {
  interface Window {
    APP_DATA: TAppData
  }
}

/**
 * **Development/Test**
 *
 * During development we have access to `process.env`, but `window.APP_DATA` hasn't been loaded. We must use
 * the values found in `process.env` to retreive the defined values.
 *
 * **Production**
 *
 * When the app is compiled then `window.APP_DATA` will be available, but not `process.env`. In this case we use
 * the values which are injected in `window.APP_DATA`.
 *
 * @param variable The defined variable name. This must be the same in both `window.APP_DATA` as in `.env` withouth `REACT_APP_`..
 */
export const getEnvironmentVariable = (variable: string, asBoolean = false): any => {
  // In production
  if (!process || !process.env || process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line compat/compat
    const map = new Map(Object.entries(window.APP_DATA))

    return asBoolean ? Boolean(map.get(variable)) : map.get(variable)
  } else {
    // Everywhere else, development / test
    return asBoolean ? process.env[`REACT_APP_${variable}`] === 'true' : process.env[`REACT_APP_${variable}`]
  }
}

export const APP_DATA: TAppData = {
  API_URL: getEnvironmentVariable('API_URL'),
  I18NEXT_DEBUG: getEnvironmentVariable('I18NEXT_DEBUG', true),
  SENTRY_DEBUG: getEnvironmentVariable('SENTRY_DEBUG'),
  SENTRY_DSN: getEnvironmentVariable('SENTRY_DSN'),
  SENTRY_ENABLED: getEnvironmentVariable('SENTRY_ENABLED', true),
  SENTRY_ENVIRONMENT: getEnvironmentVariable('SENTRY_ENVIRONMENT'),
  USE_MOCKUP_DATA: getEnvironmentVariable('USE_MOCKUP_DATA', true),
  USE_REACTOTRON: getEnvironmentVariable('USE_REACTOTRON', true),
  COOKIE_POLICY_URL: getEnvironmentVariable('COOKIE_POLICY_URL'),
}
