import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'

/* Import utilities here */

/* Import components here */
import { Page } from '../../components/layout'
import { ContentArea, Layout } from '../../components/styled'
import { ResetPasswordForm } from '../../components'

// interface  s
export interface ResetPasswordProps extends RouteComponentProps {
  companyName: string
  orgNo: string
  name: string
  email: string
}

// Component
export const ResetPassword: React.FunctionComponent<ResetPasswordProps> = () => {
  const { t } = useTranslation('reset-password')
  return (
    <Page hideNav>
      <Layout>
        <ContentArea>
          <h1>{t('page-title')}</h1>
          <ResetPasswordForm />
        </ContentArea>
      </Layout>
    </Page>
  )
}

/** @component */
const ResetPasswordPage = withRouter(ResetPassword)
export default ResetPasswordPage
