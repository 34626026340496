import React from 'react'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers'
import styled from 'styled-components/macro'

// /* Import components here */
import { Button, FormErrorMessage, Input } from '..'

/* Import interfaces here */
import { LoginFormProps, TFormData } from './LoginForm.interfaces'

/** Import utilities here */
import { LoginSchema } from './LoginForm.validation'

/* CSS */
const StyledFormGroup = styled.div`
  margin-bottom: 2rem;
`

const SubmitRow = styled(StyledFormGroup)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`

export const LoginForm = ({ errorMessage, extraButton, onSubmit }: LoginFormProps): JSX.Element => {
  const { t } = useTranslation('login')
  const { register, handleSubmit, formState, errors } = useForm<TFormData>({
    resolver: yupResolver(LoginSchema),
    mode: 'onBlur',
  })

  const onHandleSubmit = ({ email, password }: TFormData): void => {
    onSubmit(email, password)
  }

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      {errorMessage && <FormErrorMessage>{t(`login:response.${errorMessage}`)}</FormErrorMessage>}
      <label htmlFor="email">{t('email')}</label>
      <StyledFormGroup>
        <Input
          autoComplete="username"
          className={errors.email ? 'danger' : 'basic'}
          inputRef={register}
          name="email"
          placeholder={t('enter-email')}
        />
        {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
      </StyledFormGroup>
      <StyledFormGroup>
        <label htmlFor="password">{t('password')}</label>
        <Input
          autoComplete="current-password"
          className={errors.password ? 'danger' : 'basic'}
          inputRef={register}
          inputType="password"
          name="password"
          placeholder={t('enter-password')}
        />
        {errors.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
      </StyledFormGroup>
      <SubmitRow>
        <Button disabled={formState.isSubmitting} type="submit" variant="primary">
          {formState.isSubmitting ? <FontAwesomeIcon spin icon={faSpinnerThird} /> : t('log-in-btn')}
        </Button>
        {extraButton}
      </SubmitRow>
    </form>
  )
}
