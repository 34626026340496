import React, { useContext } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

// Import components
import { Page } from 'components/layout'
import { ContentArea, Layout } from 'components/styled'
import { AuthenticationContext } from 'contexts'
import { Routes } from 'config'
import { useLoginWithSessionId } from 'hooks'
import { api } from 'api'
import { useTranslation } from 'react-i18next'
import { ButtonLink } from 'components'

export function BankIdAuthenticationPage(): JSX.Element {
  const authenticationContext = useContext(AuthenticationContext)
  const [t] = useTranslation('common')
  const { search } = useLocation()

  /** sessionId generated after successfull bankId authentication. Used to get JWT. */
  // eslint-disable-next-line compat/compat
  const sessionId = new URLSearchParams(search).get('grandidsession')

  const { data, isLoading, isError, error } = useLoginWithSessionId(sessionId)

  if (isLoading) {
    return (
      <Page hideNav>
        <Layout>
          <ContentArea>
            <h1>{t('loading')}</h1>
          </ContentArea>
        </Layout>
      </Page>
    )
  }

  /**
   * TODO: Add better UX for errors
   */
  if (isError) {
    return (
      <Page hideNav>
        <Layout>
          <ContentArea>
            <h1>{t('registration:something-went-wrong')}</h1>
            {error && <p>{error.message}</p>}
            <ButtonLink to={Routes.Root}>{t('registration:back-to-start')}</ButtonLink>
          </ContentArea>
        </Layout>
      </Page>
    )
  }

  if (data?.Jwt) {
    api.setToken(data.Jwt)
    api.addLoginDataToSessionStorage(data)

    if (data.NeedsSetup) {
      return <Redirect to={Routes.Registration} />
    } else {
      authenticationContext.login(data)
      return <Redirect to={Routes.Start} />
    }
  }

  // If the JWT was missing in the response, redirect the user back to the login page
  return <Redirect to={Routes.Root} />
}
