import styled from 'styled-components/macro'

export const StyledCheckbox = styled.div``

export const IconAndLabel = styled.div`
  display: flex;
`

export const Box = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  cursor: pointer;

  background: white;
  color: ${({ theme }): string => theme.colors.secondary};

  border: 1px solid;
  border-radius: 0.25rem;

  height: 1.5rem;
  width: 1.5rem;

  outline-style: none;

  transition: color 0.2s ease-out;

  &:hover {
    color: ${({ theme }): string => theme.colors.secondaryLight};
    transition: color 0.2s ease-out;
  }
`

export const Label = styled.span`
  margin-left: 1rem;
`

export const Message = styled.p`
  display: block;
  margin-left: 2.5rem;
  margin-top: 0.25rem;

  /* Variants */
  .error & {
    color: ${({ theme }): string => theme.colors.red};
  }
`
