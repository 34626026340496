export const Routes = {
  Root: '/',
  BusinessLogin: '/businesslogin',
  BankIdAuthentication: '/authenticate',
  Registration: '/registration',
  ResetPassword: '/reset-password',
  Start: '/start',
  Vehicles: '/vehicles',
  Services: '/services',
  Profile: '/profile',
}
