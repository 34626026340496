import React from 'react'
import { useTranslation } from 'react-i18next'

// Import components
import { Page } from 'components/layout'
import { ContentArea, Layout } from 'components/styled'
import { UserRegistrationForm } from 'components'

export function RegistrationPage(): JSX.Element {
  const { t } = useTranslation('registration')

  return (
    <Page hideNav portalType="private">
      <Layout>
        <ContentArea>
          <h1>{t('title')}</h1>
          <UserRegistrationForm />
        </ContentArea>
      </Layout>
    </Page>
  )
}
