import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { Trans, useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserBlob, api } from 'api'

import { Button, FormErrorMessage } from 'components'
import { Routes } from 'config'
import { assignUserIdentity, setUserInfo } from 'hooks'
import { AuthenticationContext, VehiclesContext } from 'contexts'
import { GetVehicleTypeIcon, capitalize, getErrorMessage, toLocaleDateString } from 'utils'

import {
  OtherVehicles,
  StyledCard,
  StyledLicenseCard,
  VehicleFoundDetails,
  VehicleFoundTitle,
} from './UserRegistrationForm.styles'
import { UserRegistrationFormContactData, UserRegistrationFormVehicleData } from './UserRegistrationForm.types'
import { ContactInfoForm } from './ContactInfoForm'
import { VehicleInfoForm } from './VehicleInfoForm'

const numberOfVehiclesToShow = 6

export const UserRegistrationForm = (): JSX.Element => {
  const authenticationContext = useContext(AuthenticationContext)
  const vehiclesContext = useContext(VehiclesContext)
  const { t } = useTranslation('registration')
  const [errorMessage, setErrorMessage] = useState<string>()
  const [addedVehiclePlateNumber, setAddedVehiclePlateNumber] = useState<string>()
  const [formStep, setFormStep] = useState<'contact' | 'vehicle' | 'noVehicle' | 'success' | 'license'>('contact')
  const history = useHistory()
  const [userBlob, setUserBlob] = useState<UserBlob | undefined>(undefined)

  const [mutateSetUserInfo, { error: errorSetUserInfo, isLoading: isLoadingSetUserInfo }] = useMutation(setUserInfo, {
    onError: error => {
      const err = getErrorMessage(error)
      setErrorMessage(err)
    },
    onSuccess: () => {
      setFormStep('vehicle')
      setErrorMessage(undefined)
    },
  })

  const [
    mutateAssignUserIdentity,
    { error: errorAssignUserIdentity, isLoading: isLoadingAssignUserIdentity },
  ] = useMutation(assignUserIdentity, {
    onError: error => {
      const latestInfoFromTs = toLocaleDateString(authenticationContext.data?.LatestInfofromTs || '')

      const err = getErrorMessage(error, {
        notFound: t('error:registration.vehicleNotFound'),
        unknownError: t('error:registration.notVerifiedOwner', { latestInfoFromTs }),
      })
      setErrorMessage(err)
    },
  })

  async function submitContactForm(data: UserRegistrationFormContactData): Promise<void> {
    if (!data) return

    const userBlob = await mutateSetUserInfo({ email: data.email, phone: data.phone })

    if (!userBlob) return

    try {
      // Update userblob in context and sessionStorage
      setUserBlob(userBlob)
    } catch (error) {
      console.error(error)
    }
  }

  async function submitVehicleForm(data: UserRegistrationFormVehicleData): Promise<void> {
    if (!data) return

    const jwt = await mutateAssignUserIdentity({ regNo: data.regNo })

    if (!jwt) return

    // Update jwt in api and in context
    api.setToken(jwt)
    authenticationContext.updateUserJwt(jwt)

    // Get vehicles and bookings for the user
    await vehiclesContext.fetchVehiclesAndBookings()

    setAddedVehiclePlateNumber(data.regNo.toLocaleUpperCase())

    // Navigate to last step
    setFormStep('success')
    setErrorMessage(undefined)
  }

  function noVehicles(): void {
    setFormStep('noVehicle')
  }

  function goToCustomerPortal(): void {
    if (!userBlob) {
      history.push(Routes.Root)
      return
    }

    authenticationContext.login(userBlob)
    api.addLoginDataToSessionStorage(userBlob)
    history.push(Routes.Start)
  }

  function goToRoot(): void {
    authenticationContext.logout()
  }

  function goToLicense(): void {
    setFormStep('license')
  }

  function addedVehicleInfo(): JSX.Element {
    if (!addedVehiclePlateNumber) {
      Sentry.withScope(function (scope) {
        scope.setLevel(Sentry.Severity.Warning)
        Sentry.captureMessage('Missing plate number but still success')
      })
      return <></>
    }

    const vehicle = vehiclesContext.getVehicleFromContext(addedVehiclePlateNumber)

    if (!vehicle) {
      Sentry.withScope(function (scope) {
        scope.setLevel(Sentry.Severity.Warning)
        Sentry.captureMessage('No vehicles but still success')
      })
      return <></>
    }

    return (
      <div>
        <VehicleFoundTitle>{t('vehicleForm.success')}</VehicleFoundTitle>
        <VehicleFoundDetails>
          <FontAwesomeIcon icon={GetVehicleTypeIcon(vehicle.VehicleType)} />
          <span>
            {vehicle.RegNo}
            <br />
            {vehicle.Color && vehicle.Color.trim().toLowerCase() !== 'okänd' && capitalize(vehicle.Color)}{' '}
            {vehicle.VehicleDescription && capitalize(vehicle.VehicleDescription)} {vehicle.YearModel}
          </span>
        </VehicleFoundDetails>
      </div>
    )
  }

  if (formStep === 'contact') {
    return (
      <StyledCard className="registration-form-content-contact">
        <h1>{t('contactForm.title')}</h1>
        <p>{t('contactForm.description')}</p>
        {errorSetUserInfo && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        <ContactInfoForm disableSubmitButton={isLoadingSetUserInfo} onSubmit={submitContactForm} />
      </StyledCard>
    )
  }

  if (formStep === 'vehicle') {
    return (
      <StyledCard className="registration-form-content-vehicle">
        <h1>{t('vehicleForm.title')}</h1>
        <p>{t('vehicleForm.description')}</p>
        {errorAssignUserIdentity && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        <VehicleInfoForm
          disableSubmitButton={isLoadingAssignUserIdentity}
          noVehicles={noVehicles}
          onSubmit={submitVehicleForm}
        />
      </StyledCard>
    )
  }

  if (formStep === 'success') {
    const otherVehicles = vehiclesContext.fetchedVehicles && vehiclesContext.fetchedVehicles.length > 1 && (
      <OtherVehicles>
        <p>{t('foundMoreVehicles')}</p>
        <ul>
          {vehiclesContext.fetchedVehicles
            .filter(vehicle => vehicle.RegNo !== addedVehiclePlateNumber)
            .slice(0, numberOfVehiclesToShow - 1)
            .map(vehicle => {
              return (
                <li key={vehicle.RegNo}>
                  <FontAwesomeIcon icon={GetVehicleTypeIcon(vehicle.VehicleType)} />
                  {vehicle.RegNo}
                </li>
              )
            })}
        </ul>
        {vehiclesContext.totalVehicles > numberOfVehiclesToShow && (
          <p>
            {t('foundNOfOtherVehicles', {
              numberOfVehicles: vehiclesContext.totalVehicles - numberOfVehiclesToShow,
            })}
          </p>
        )}
      </OtherVehicles>
    )

    return (
      <StyledCard className="registration-form-content-success">
        <h1>{t('vehicleForm.title')}</h1>
        {addedVehiclePlateNumber && addedVehicleInfo()}
        {otherVehicles}
        <Button variant="accent" onClick={goToLicense}>
          {t('continue')}
        </Button>
      </StyledCard>
    )
  }

  if (formStep === 'license') {
    return (
      <StyledLicenseCard className="registration-form-content-license">
        <h1>{t('licenseForm.title')}</h1>
        <p>
          {' '}
          <Trans i18nKey="licenseForm.description1">
            Användaravtal för kundportalen hittar du{' '}
            <a href="https://opus.se/anvandarvilkor-for-kundportalen" rel="noopener noreferrer" target="_blank">
              här
            </a>
            <br />
            Integritetspolicyn hittar du{' '}
            <a href="https://opus.se/integritetspolicy-kundportalen" rel="noopener noreferrer" target="_blank">
              här
            </a>
          </Trans>
        </p>
        <p>
          <Trans i18nKey="licenseForm.description2">
            Vill du radera ditt användarkonto meddelar du oss skriftligen.
            <br />
            Vill du hellre använda en standardblankett finns den tillgänglig{' '}
            <a
              href="https://www.konsumentverket.se/globalassets/publikationer/kontrakt-och-mallar/angerblankett-2015-konsumentverket.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              här
            </a>
          </Trans>
        </p>
        <Button variant="accent" onClick={goToCustomerPortal}>
          {t('open-customerportal')}
        </Button>
      </StyledLicenseCard>
    )
  }

  if (formStep === 'noVehicle') {
    return (
      <StyledCard className="registration-form-content-novehicle">
        <h1>{t('noVehicle.title')}</h1>
        <p>{t('noVehicle.description1')}</p>
        <p>{t('noVehicle.description2')}</p>
        <Button onClick={goToRoot}>{t('back-to-start')}</Button>
      </StyledCard>
    )
  }

  return <></>
}
