import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import * as React from 'react'
import { withTranslation } from 'react-i18next'

/* Import utilities here */
import { api } from 'api'
import { capitalize, nonull, toDateString, toLocaleDateString, toOrgNoFormat, toStandardRegNoFormat } from 'utils'
import { Routes } from 'config'

/* Import components here */
import {
  ContactInformationFormik,
  NotesFormik,
  NotificationSettingsFormik,
  StyledTwoColumnLayout,
  StyledVehicleDetails,
} from '.'
import { Button, ButtonLink } from '..'

/* Import constants here */
import { GetVehicleStatusCode, ProductCodes, VehicleTypeNames } from './VehicleDetails.constants'
import { withAuthenticationContext_DEPRECATED } from 'contexts'
import { GetVehicleTypeIcon } from 'utils'

/* Import interfaces here */
import { VehicleDetailsProps, VehicleDetailsState } from './VehicleDetails.interfaces'

// Component
export class VehicleDetails extends React.Component<VehicleDetailsProps, VehicleDetailsState> {
  public state: VehicleDetailsState = {
    showVirs: false,
  }

  public getInspectionDocuments = async (): Promise<void> => {
    const { data: inspectionDocuments } = await api.vehicles.getInspectionDocuments(this.props.RegNo)

    if (inspectionDocuments) {
      this.setState({
        doc: inspectionDocuments,
      })
    } else {
      this.setState({
        doc: null,
      })
    }
  }

  /**
   * Check if local alias in VehicleContext is available, and use it when opening vehicle modal.
   * Later update with latest information from API.
   * This makes the alias appear from the start without any delays. If the value is the same as in response from the API then the user will never notice a change.
   */
  public getAlias = (): string => {
    // Alias = Coming from VehicleContext when fetching all vehicles
    // VehicleAlias = Comes from `api/Vehicles/{RegNo}/info, when opening a vehicle
    const { Alias, VehicleAlias } = this.props

    return VehicleAlias ? VehicleAlias : Alias
  }

  /**
   * Show booking details for a vehicle
   */
  public renderBooking = (): JSX.Element => {
    const { Booking, t } = this.props
    const products = Booking!.Items!.map(item => <li key={item.ProductId}>{item.Product}</li>)

    return (
      <section>
        <h3>{t('vehicles:booking')}</h3>
        <StyledTwoColumnLayout>
          <div className="form-group">
            <ul>
              <li>
                <b>{t('common:date')}:</b> {(Booking && toDateString(Booking.BookingTime!)) || t('common:no-data')}
              </li>
              <li>
                <b>{t('common:station')}:</b> {(Booking && Booking.Station) || t('common:no-data')}
              </li>
            </ul>
          </div>
          <div className="form-group">
            <ul>
              <li>
                <b>{t('common:payment')}:</b> {Booking!.PaymentStatusText}
              </li>
              <li>
                <b>{t('common:products')}:</b>
              </li>
              <ul>{products}</ul>
            </ul>
          </div>
        </StyledTwoColumnLayout>
      </section>
    )
  }

  public toggleShowVirs = async (): Promise<void> => {
    if (!this.state.doc) {
      this.setState({
        loading: true,
      })

      await this.getInspectionDocuments()

      this.setState({
        loading: false,
      })
    }

    this.setState({
      showVirs: !this.state.showVirs,
    })
  }

  public render(): JSX.Element {
    const {
      Booking,
      Color,
      HasClutchDevice,
      HasDrivingBan,
      IdentityNumber,
      InspectionDeadline,
      LastInspected,
      authenticationContext,
      Owner,
      RegNo,
      SendEmail,
      SendSMS,
      t,
      TotalWeight,
      VehicleDescription,
      VehicleStatusCode,
      VehicleType,
      VehicleUserEmail,
      VehicleUserName,
      VehicleUserNotes,
      VehicleUserPhone,
      YearModel,
      onBookingClickCallback,
      ...props
    } = this.props

    const vehicleTypeIcon = GetVehicleTypeIcon(VehicleType)

    const vehicleStatusCode = GetVehicleStatusCode(VehicleStatusCode)

    return (
      <StyledVehicleDetails {...props}>
        <header className="meta-header">
          <section>
            <p
              className={classNames(
                'last-inspection-date',
                new Date(InspectionDeadline || 0).getTime() <= Date.now() && 'past-due',
              )}
            >
              {t('vehicles:inspect-last')}:{' '}
              {(InspectionDeadline && toLocaleDateString(InspectionDeadline)) || t('no-data')}
            </p>
            <h2 className="regno">{(RegNo && toStandardRegNoFormat(RegNo)) || '––– –––'}</h2>
          </section>
          <footer>
            <ButtonLink noArrowIcon={true} to={Routes.Vehicles}>
              {t(`common:close`)}
            </ButtonLink>
            <Button
              className={Booking ? 'accent' : ''}
              iconRight={faChevronRight}
              iconRightSmall={true}
              onClick={() => {
                onBookingClickCallback(RegNo || '', nonull(VehicleUserEmail), nonull(VehicleUserPhone))
              }}
            >
              {this.props.Booking ? t('vehicles:show-booking') : t('vehicles:book-inspection')}
            </Button>
          </footer>
        </header>
        <section className="details">
          <section>
            <h3>
              {t('common:vehicle')} {t('common:and')} {t('common:status').toLowerCase()}
            </h3>
            <div
              className="vehicle-info"
              style={{
                marginBottom: '1rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon icon={vehicleTypeIcon} style={{ fontSize: '1.5rem', marginRight: '1rem' }} />
              <span className="VehicleDescription">
                {Color && Color.trim().toLowerCase() !== 'okänd' && capitalize(Color)}{' '}
                {VehicleDescription && capitalize(VehicleDescription)} {YearModel}
              </span>
            </div>
            <StyledTwoColumnLayout>
              <div className="form-group">
                <h4 className="label">{t('common:inspection')}</h4>
                <ul>
                  <li>
                    <b>{t('vehicles:inspection-valid-until')}:</b>{' '}
                    {(InspectionDeadline && toDateString(InspectionDeadline)) || t('no-data')}
                  </li>
                  <li>
                    <b>{t('vehicles:last-inspected')}:</b>{' '}
                    {(LastInspected && toDateString(LastInspected)) || t('no-data')}
                  </li>
                </ul>
              </div>
              <div className="form-group">
                <h4 className="label">{t('common:status')}</h4>
                <ul>
                  <li>
                    <b>{t('vehicles:in-traffic')}:</b> {vehicleStatusCode ? t('common:yes') : t('common:no')}
                  </li>
                  <li>
                    <b>{t('vehicles:driving-ban')}:</b> {HasDrivingBan ? t('common:yes') : t('common:no')}
                  </li>
                </ul>
              </div>
              <div className="form-group">
                <h4 className="label">{t('vehicles:vehicle-info')}</h4>
                <ul>
                  <li>
                    <b>{t('vehicles:total-weight')}:</b> {TotalWeight} kg
                  </li>
                  <li>
                    <b>{t('vehicles:coupling-unit')}:</b> {HasClutchDevice ? t('common:yes') : t('common:no')}
                  </li>
                  <li>
                    <b>{t('vehicles:vehicle-type')}:</b> {VehicleType && VehicleTypeNames[VehicleType.trim()]}
                  </li>
                </ul>
              </div>
              {authenticationContext.customerType === 'company' && (
                <div className="form-group">
                  <h4 className="label">{t('vehicles:belonging')}</h4>
                  <ul>
                    <li>
                      <b>{t('vehicles:org-no')}:</b> {Owner && toOrgNoFormat(Owner)}
                    </li>
                    <li>
                      <b>{`${t('vehicles:user')} ${t('vehicles:org-no').toLowerCase()}`}:</b>{' '}
                      {IdentityNumber && toOrgNoFormat(IdentityNumber)}
                    </li>
                  </ul>
                </div>
              )}
            </StyledTwoColumnLayout>
          </section>
          <span className="align-right">
            <Button variant="secondary" onClick={this.toggleShowVirs}>
              {t('vehicles:show-inspection-protocols')}
            </Button>
          </span>
          {Booking && this.renderBooking()}
          <StyledTwoColumnLayout rowGap={true} style={{ marginTop: '1rem' }}>
            {this.state.showVirs &&
              (this.state.doc && this.state.doc.Virs && this.state.doc.Virs.length ? (
                this.state.doc.Virs.map(
                  (doc, idx): JSX.Element => {
                    const { StartInspectionDate, InspectionType } = doc

                    // FIXME: It must be a better way to handle this. This is a check because InspectionType and StartInspectionDate could be undefined.
                    if (!!InspectionType && !!StartInspectionDate) {
                      return (
                        <ButtonLink
                          key={idx}
                          // FIXME: Should the following line be removed?
                          // className="fullWidth" //Orsakar att texten går utanför det modala fönstret.
                          style={{ margin: 0 }}
                          target="_blank"
                          to={{
                            pathname: nonull(doc.VirLink),
                          }}
                        >
                          {toLocaleDateString(StartInspectionDate)} – {ProductCodes[InspectionType.trim() as any]}
                        </ButtonLink>
                      )
                    }
                    return <React.Fragment key={idx} />
                  },
                )
              ) : (
                <p>{t('vehicles:no-inspection-protocols')}</p>
              ))}
          </StyledTwoColumnLayout>
          <section>
            <h3>{t('vehicles:user')}</h3>
            <ContactInformationFormik
              Email={VehicleUserEmail}
              Name={VehicleUserName}
              Phone={VehicleUserPhone}
              submitHandler={this.props.submitContactInformation}
            />
          </section>
          <section>
            <h3>{t('settings:notification-settings')}</h3>
            <NotificationSettingsFormik
              GetEmail={nonull(SendEmail)}
              GetSms={nonull(SendSMS)}
              submitHandler={this.props.submitNotificationSettings}
            />
          </section>
          {authenticationContext.customerType === 'company' && (
            <section>
              <h3>{t('vehicles:personal-notes')}</h3>
              <NotesFormik
                Alias={this.getAlias()}
                Notes={VehicleUserNotes}
                submitHandler={this.props.submitUserNotes}
              />
            </section>
          )}
          <span className="align-right">
            <ButtonLink noArrowIcon={true} to={Routes.Vehicles}>
              {t('common:close')}
            </ButtonLink>
          </span>
        </section>
      </StyledVehicleDetails>
    )
  }
}

/** @component */
export default React.memo(withAuthenticationContext_DEPRECATED()(withTranslation()(VehicleDetails)))
