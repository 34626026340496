import { AxiosError } from 'axios'
import { getI18n } from 'react-i18next'

interface CustomErrorMessages {
  /**
   * HTTP Status Code 404
   */
  notFound?: string

  /**
   * HTTP Status Code 401
   */
  notAuhtorized?: string

  /**
   * Missing HTTP Status Code
   */
  unknownError?: string
}

/**
 * Get a translated error message based on the HTTP status code. Will use default error messages, but those can be overriden.
 *
 * @param error {AxiosError} Error from Axios
 * @param customErrorMessage Custom error messages for each HTTP request status code
 *
 * @returns A translated string of the error message
 */
export function getErrorMessage(error: AxiosError, customErrorMessage: CustomErrorMessages = {}): string {
  const i18n = getI18n()

  // Error 😨
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */

    if (error.response.status === 401) {
      return customErrorMessage.notAuhtorized || i18n.t('error:defaultErrorMessages.notAuthorized')
    } else if (error.response.status === 404) {
      return customErrorMessage.notFound || i18n.t('error:defaultErrorMessages:notFound')
    }
  }

  return customErrorMessage.unknownError || i18n.t('error:defaultErrorMessages:unknownError')
}
