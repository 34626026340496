import React, { useState } from 'react'

import { CustomerType, UserBlob, api } from 'api'

export type TCustomerType = 'company' | 'private'

export interface AuthenticationContext {
  isAuthenticated: boolean
  data?: UserBlob
  login(data: UserBlob): void
  updateUserJwt(jwt: string): void
  logout(): void
  customerType: TCustomerType | undefined
}

/**
 * Default state for authentication.
 */
const authenticationContextDefault: AuthenticationContext = {
  isAuthenticated: false,
  login: () => undefined,
  logout: () => undefined,
  updateUserJwt: () => undefined,
  data: undefined,
  customerType: undefined,
}

export const AuthenticationContext = React.createContext(authenticationContextDefault)

export const AuthenticationProvider: React.FC = ({ children }): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [userBlob, setUserBlob] = useState<UserBlob>()

  /**
   * Set local state to logged in and store the user data in context
   *
   * @param data {UserBlob} A logged in user's data
   */
  const login = (data: UserBlob): void => {
    setIsAuthenticated(true)
    setUserBlob(data)
  }

  /**
   * Update the logged in user's Jwt
   *
   * @param jwt {string}
   */
  const updateUserJwt = (jwt: string): void => {
    const newUserBlob: UserBlob = { ...userBlob, Jwt: jwt }

    setUserBlob(newUserBlob)
  }

  const logout = (): void => {
    api.logout()

    // FIXME: This is an ugly hotfix which must be fixed. This makes sure there are no cached vehicles after logging out.
    window.location.pathname = '/'
  }

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated,
        data: userBlob,
        updateUserJwt,
        login,
        logout,
        get customerType(): TCustomerType | undefined {
          if (this.data?.CustomerType === CustomerType.NUMBER_0) return 'company'

          if (this.data?.CustomerType === CustomerType.NUMBER_1) return 'private'

          return undefined
        },
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

/**
 * HOC to add context to a component.
 *
 * Use the hook `useContext` instead.
 *
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/class-name-casing
export interface WithAuthenticationContext_DEPRECATED {
  authenticationContext: AuthenticationContext
}

export const AuthenticationConsumer = AuthenticationContext.Consumer

/**
 * Please migrate to `useContext()` hook instead.
 * @deprecated
 */
export function withAuthenticationContext_DEPRECATED<P>() {
  return function Wrapper(WrapperComponent: React.ComponentType<any>): React.ComponentType<any> {
    class WithContext extends React.Component {
      render() {
        const { ...props } = this.props

        return React.createElement(AuthenticationConsumer, null, (ctx: AuthenticationContext) =>
          React.createElement(WrapperComponent, {
            authenticationContext: {
              ...ctx,
            },
            ...props,
          }),
        )
      }
    }

    return WithContext as React.ComponentType<P>
  }
}
