import React, { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { AxiosResponse } from 'axios'
import * as yup from 'yup'
import { Trans, getI18n } from 'react-i18next'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'

import { UserBlob, api } from 'api'
import { AuthenticationContext } from 'contexts'
import { AuthenticatedPage, Button, Checkbox, FormErrorMessage, Input } from 'components'
import { ContentArea, Layout } from 'components/styled'

import { FormRow, LicenseAndPolicyWrapper, StyledSuccessMessage, SubmitRow } from './ProfilePage.styles'

function mutateProfile({ email, phone, blockMarketing }): Promise<AxiosResponse<UserBlob>> {
  return api.accounts.setUserInfo(email, phone, blockMarketing)
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email(getI18n().t('registration:email-valid'))
    .required(getI18n().t('registration:email-required')),
  phone: yup.string().required(getI18n().t('registration:phone-required')),
  blockMarketing: yup.boolean(),
})

const ShowWithDelay: React.FC<{ show: boolean; delay?: number }> = function ShowWithDelay({
  children,
  show = false,
  delay = 500,
}) {
  const [render, setRender] = useState(false)
  const timerRef = useRef(0)

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    if (show) {
      timerRef.current = setTimeout(() => setRender(true), delay)
    } else {
      setRender(false)
    }

    return (): void => {
      clearTimeout(timerRef.current)
    }
  }, [show, delay])

  if (render) {
    return <>{children}</>
  }
  return null
}

export function ProfilePage(): JSX.Element {
  const { t } = useTranslation()
  const authContext = useContext(AuthenticationContext)
  const { register, reset, formState, handleSubmit, errors, getValues, setValue, watch } = useForm({
    defaultValues: {
      email: authContext.data?.Email || '',
      phone: authContext.data?.Phone || '',
      blockMarketing: authContext.data?.BlockMarketing || false,
    },
    resolver: yupResolver(schema),
  })

  const [submittedData, setSubmittedData] = React.useState({})

  const [mutate, { isSuccess, isError, isLoading, reset: resetMutationState }] = useMutation(mutateProfile, {
    onSuccess({ data: userBlob }) {
      // Update userblob in context and sessionStorage
      authContext.login(userBlob)
      api.addLoginDataToSessionStorage(userBlob)
    },
  })

  const onSubmit = handleSubmit(({ email, phone, blockMarketing }) => {
    mutate({ email, phone, blockMarketing })
    setSubmittedData({ email, phone, blockMarketing })
  })

  React.useEffect(() => {
    if (isSuccess) {
      reset({ ...submittedData })
    }
  }, [submittedData, reset, isSuccess])

  React.useEffect(() => {
    register({ name: 'blockMarketing' })
  }, [register])

  const disabled = !formState.isDirty || formState.isSubmitting

  function onFormChange(): void {
    if (!formState.isDirty) {
      resetMutationState()
    }
  }
  function onBlockMarketCheckbox(): void {
    setValue('blockMarketing', !getValues('blockMarketing'), { shouldDirty: true })
  }

  return (
    <AuthenticatedPage>
      <Layout>
        <ContentArea>
          <h1>Profil</h1>
          <form onChange={onFormChange} onSubmit={onSubmit}>
            <FormRow>
              <label htmlFor="email">{t('common:email')}</label>
              <Input className={errors.email ? 'danger' : 'basic'} inputRef={register} name="email" />
              {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
            </FormRow>

            <FormRow>
              <label htmlFor="phone">{t('common:phone')}</label>
              <Input className={errors.phone ? 'danger' : 'basic'} inputRef={register} name="phone" />
              {errors.phone && <FormErrorMessage>{errors.phone.message}</FormErrorMessage>}
            </FormRow>

            <FormRow>
              <Checkbox
                checked={watch('blockMarketing')}
                label={t('registration:blockMarketingMessage')}
                onClick={onBlockMarketCheckbox}
              />
            </FormRow>
            {isSuccess && !formState.isDirty && (
              <FormRow>
                <StyledSuccessMessage>{t('profile:profileUpdated')}</StyledSuccessMessage>
              </FormRow>
            )}
            {isError && (
              <FormRow>
                <FormErrorMessage>{t('profile:couldNotUpdate')}</FormErrorMessage>
              </FormRow>
            )}
            <SubmitRow>
              <Button disabled={disabled} variant="primary">
                <ShowWithDelay show={isLoading}>
                  <FontAwesomeIcon spin icon={faSpinnerThird} />
                </ShowWithDelay>{' '}
                {t('common:save')}
              </Button>
              <LicenseAndPolicyWrapper>
                <Trans i18nKey="licenseForm.description1">
                  Användaravtal för kundportalen hittar du{' '}
                  <a href="https://opus.se/anvandarvilkor-for-kundportalen" rel="noopener noreferrer" target="_blank">
                    här
                  </a>
                  <br />
                  Integritetspolicyn hittar du{' '}
                  <a href="https://opus.se/integritetspolicy-kundportalen" rel="noopener noreferrer" target="_blank">
                    här
                  </a>
                </Trans>
              </LicenseAndPolicyWrapper>
            </SubmitRow>
          </form>
        </ContentArea>
      </Layout>
    </AuthenticatedPage>
  )
}
