import { AxiosResponse } from 'axios'

import {
  AccountApi,
  BookingsApi,
  Configuration,
  ConfigurationParameters,
  LoginDto,
  UserBlob,
  VehiclesApi,
} from '@cdab/opus-api-client'
import { configParams } from 'config/api'

class Api {
  accounts: AccountApi
  bookings: BookingsApi
  vehicles: VehiclesApi

  apiToken: string | null = null

  constructor(configParams: ConfigurationParameters) {
    const config = new Configuration({
      ...configParams,
      apiKey: (): string => (this.apiToken ? `bearer ${this.apiToken}` : ''),
    })

    this.accounts = new AccountApi(config)
    this.vehicles = new VehiclesApi(config)
    this.bookings = new BookingsApi(config)
  }
  addLoginDataToSessionStorage(loginData: UserBlob): void {
    window.sessionStorage.setItem('.customer-portal_token-data', JSON.stringify(loginData))
  }

  removeLoginDataFromSessionStorage(): void {
    window.sessionStorage.removeItem('.customer-portal_token-data')
  }

  setToken(token: string): void {
    this.apiToken = token
  }

  logout(): void {
    this.apiToken = null
    this.removeLoginDataFromSessionStorage()
  }

  async login(body: LoginDto): Promise<AxiosResponse<UserBlob>> {
    const response = await this.accounts.login(body)
    const { data } = response

    this.apiToken = data.Jwt ? data.Jwt : null // Force remove undefined
    this.addLoginDataToSessionStorage(data)

    return response
  }
}
// export const accounts = new AccountApi(apiConfig)
export const api = new Api(configParams)

export * from '@cdab/opus-api-client'
