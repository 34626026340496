import * as React from 'react'
import styled from 'styled-components/macro'
import classNames from 'classnames'

const StyledFilterSection = styled.details`
  display: flex;

  summary {
    display: block;
    align-items: center;

    padding: 1rem 0;

    .label {
      display: inline-block;

      margin: 0;
    }
  }

  section {
    display: flex;
  }
`

export interface FilterSectionProps {
  label: string
  value?: any | null
  children?: JSX.Element | JSX.Element[] // FIXME: Man borde inte behöva sätta children manuellt väl?
}

const FilterSection: React.FC<FilterSectionProps> = ({ label, value, children, ...props }) => {
  return (
    <StyledFilterSection open={!!value} {...props}>
      <summary>
        <span className={classNames('label')}>{label}</span>
      </summary>
      <section>{children}</section>
    </StyledFilterSection>
  )
}

/** @component */
export default React.memo(FilterSection)
