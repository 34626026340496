import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form, FormikProps, withFormik } from 'formik'

/* Import utilities here */
import { SetNotificationInformation } from 'api'

/* Import components here */
import { StyledTwoColumnLayout } from '../styled'
import { Toggle } from '../..'
import { Button } from '../../Button'

// Props
interface NotificationSettingsFormProps extends SetNotificationInformation {
  submitHandler?(values: SetNotificationInformation): void
}

export const NotificationSettingsForm = (props: FormikProps<SetNotificationInformation>) => {
  const { isSubmitting, setFieldTouched, handleChange, initialValues, values } = props
  const { t } = useTranslation('settings')

  return (
    <Form>
      <StyledTwoColumnLayout>
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {t('sms-reminders')}{' '}
          <Field
            checked={values.GetSms}
            component={Toggle}
            name="GetSms"
            onChange={(e: React.ChangeEvent) => {
              handleChange(e)
              setFieldTouched('GetSms')
            }}
          />
        </label>
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {t('email-reminders')}{' '}
          <Field
            checked={values.GetEmail}
            component={Toggle}
            name="GetEmail"
            onChange={(e: React.ChangeEvent) => {
              handleChange(e)
              setFieldTouched('GetEmail')
            }}
          />
        </label>
      </StyledTwoColumnLayout>
      {JSON.stringify(values) !== JSON.stringify(initialValues) && (
        <div className="align-right">
          <Button disabled={isSubmitting} type="submit" variant="primary">
            {t('common:save')}
          </Button>
        </div>
      )}
    </Form>
  )
}

// Component
export const NotificationSettingsFormik = withFormik<NotificationSettingsFormProps, SetNotificationInformation>({
  mapPropsToValues: (props: SetNotificationInformation) => ({
    GetEmail: props.GetEmail || false,
    GetSms: props.GetSms || false,
  }),
  handleSubmit: (values: SetNotificationInformation, { props, setSubmitting }) => {
    if (props.submitHandler) {
      props.submitHandler(values)
      setSubmitting(false)
    }
  },
  enableReinitialize: true,
})(NotificationSettingsForm)

/** @component */
export default NotificationSettingsFormik
