import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import styled from 'styled-components/macro'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

/* Import utilities here */
import { capitalize, toLocaleDateString, toStandardRegNoFormat } from '../../utils'

/* Import components here */
import { ButtonLink } from '../'
import { Button } from '../'
import { Card } from '../Card' // Cannot import the exported version for some reason
import { FutureBookingInfo } from 'api'

const StyledVehicleListing = styled(Card)`
  display: flex;
  flex-direction: column;
  line-height: 1;

  @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
    flex-direction: row;
  }

  section {
    display: flex;
    justify-content: space-between;

    flex-direction: column;

    @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
      flex-direction: column;
    }

    /* Icon showed for booking */
    svg[class*='svg-inline-'] {
      color: ${props => props.theme.colors.accent};
      margin-right: 0.5rem;
    }

    .last-inspection-date {
      order: 2;

      font-weight: 500;

      @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
        order: 1;

        margin-top: 0;
        margin-bottom: 0.5rem;
      }
    }

    .vehicle-info {
      order: 3;
    }

    .regno {
      @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
        order: 2;
      }
    }
  }

  footer {
    display: flex;
    flex: 1;

    align-items: center;
    justify-content: space-between;

    margin-top: 1rem;

    @media screen and (min-width: ${props => props.theme.sizes.mdContainer}) {
      justify-content: flex-end;

      margin: 0;
    }
  }

  .past-due {
    color: ${props => props.theme.colors.red};
  }
`

// Props
export interface VehicleListingProps extends WithTranslation, RouteComponentProps {
  Alias: string | null
  Color?: string
  VehicleDescription?: string
  YearModel?: number
  Booking?: FutureBookingInfo
  RegNo: string | null
  InspectionDeadline?: string
  onBookingClickCallback: (RegNo: string) => void
}

// Component
class Vehicle extends React.Component<VehicleListingProps> {
  /**
   * Show information regarding a booked inspection for a vehicle
   */
  renderBookingInfo = () => {
    const { Booking, t } = this.props
    const { BookingTime, Station } = Booking!

    return (
      <React.Fragment>
        <FontAwesomeIcon icon={faCalendarCheck} />
        {`${toLocaleDateString(BookingTime)}, ${Station}` || t('no-data')}
      </React.Fragment>
    )
  }

  /**
   * Show inspection deadline for a vehicle
   */
  renderInspectionDeadline = () => {
    const { InspectionDeadline, t } = this.props

    return (
      <React.Fragment>
        {t('vehicles:inspect-last')}: {(InspectionDeadline && toLocaleDateString(InspectionDeadline)) || t('no-data')}
      </React.Fragment>
    )
  }

  render() {
    const {
      t,
      match,
      Alias,
      Color,
      VehicleDescription,
      YearModel,
      Booking,
      RegNo,
      InspectionDeadline,
      onBookingClickCallback,
      ...props
    } = this.props

    return (
      <StyledVehicleListing {...props}>
        <section>
          <p
            className={classNames(
              'last-inspection-date',
              new Date(InspectionDeadline || 0).getTime() <= Date.now() && 'past-due',
            )}
          >
            {Booking ? this.renderBookingInfo() : this.renderInspectionDeadline()}
          </p>
          <h2 className="regno">
            {Alias && `${Alias} - `}
            {(RegNo && toStandardRegNoFormat(RegNo)) || '––– –––'}
          </h2>
          <p className="vehicle-info">
            {Color && Color.trim().toLowerCase() !== 'okänd' && capitalize(Color)}{' '}
            {VehicleDescription && capitalize(VehicleDescription)} {YearModel}
          </p>
        </section>
        <footer>
          <ButtonLink noArrowIcon to={`${match.url}/${RegNo}`}>
            {t(`common:more-info`)}
          </ButtonLink>
          <Button
            // TODO: Update to new booking-system (Will probably use ButtonLink)
            className={Booking ? 'accent' : 'secondary'}
            iconRight={faChevronRight}
            iconRightSmall={true}
            style={{ minWidth: '170px' }}
            onClick={() => {
              onBookingClickCallback(RegNo || '')
            }}
          >
            {Booking ? t('vehicles:show-booking') : t('vehicles:book-inspection')}
          </Button>
        </footer>
      </StyledVehicleListing>
    )
  }
}

export const VehicleListing = withRouter(withTranslation()(Vehicle))

/** @component */
export default VehicleListing
