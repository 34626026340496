import { Card } from 'components'
import { StyledButton } from 'components/Button/Button'
import styled from 'styled-components/macro'

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 1rem;

    font-size: 1.5rem;
    line-height: 1.25;

    color: ${props => props.theme.colors.primary};
  }

  form {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 2rem;

    button {
      align-self: flex-end;
    }
  }

  ${StyledButton} {
    margin-right: 0;
  }
`

export const StyledLicenseCard = styled(StyledCard)`
  ${StyledButton} {
    margin-top: 1rem;
  }
`

export const StyledFormGroup = styled.div`
  margin-bottom: 2rem;
`

export const StyledButtonGroup = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

export const VehicleFoundTitle = styled.p`
  color: ${({ theme }) => theme.colors.green};
  font-size: 1.125;
  font-weight: bold;
  margin-bottom: 0.75rem;
`

export const VehicleFoundDetails = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.5rem;
    margin-right: 1rem;
  }
`

export const OtherVehicles = styled.div`
  border-top: 1px solid #eee;
  padding-top: 0.5rem;

  ul {
    padding-left: 0;
    margin-bottom: 1rem;

    font-weight: bold;

    list-style-type: none;

    svg {
      margin-right: 1rem;
    }
  }
`
