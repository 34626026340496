import React, { Suspense } from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps, BrowserRouter as Router, Switch } from 'react-router-dom'
import * as qs from 'querystring'
import styled from 'styled-components/macro'

import { BusinessLoginPage, ErrorPage } from '.'
import { BankIdAuthenticationPage } from './BankIdAuthentication'
import { LandingPage } from './Landing'
import { ResetPasswordPage } from './ResetPassword'
import { ServicesPage } from './Services'
import { StartPage } from './Start'
import { VehiclesPage } from './Vehicles'
import { RegistrationPage } from './Registration'
import { ProfilePage } from './Profile'

import { Routes } from 'config'
import { LoadingIndicator } from 'components/styled'
import { useAuthCheck } from '../hooks'
import { AuthenticationContext } from 'contexts'

const StyledLoadingIndicator = styled(LoadingIndicator)`
  height: '100vh';
`

export const AppRouter: React.FunctionComponent<RouteProps> = () => {
  const authenticationContext = React.useContext(AuthenticationContext)
  const isCheckingAuthentication = useAuthCheck()

  const redirectToStart = (): JSX.Element => {
    return <Redirect to={Routes.Start} />
  }

  function redirectTo(location) {
    return {
      pathname: Routes.Root,
      search: location.pathname.substr(1).length
        ? qs.stringify({
            callback: location.pathname,
          })
        : '',
    }
  }

  function authHandler({ location }: RouteComponentProps): JSX.Element {
    if (isCheckingAuthentication) {
      return <StyledLoadingIndicator />
    }

    if (!authenticationContext.isAuthenticated) {
      return <Redirect to={redirectTo(location)} />
    }

    return (
      <Switch>
        {/* <Route component={RegistrationPage} path={Routes.Registration} /> // TODO: Do not allow this route if logged in AND do not allow this route if logged out. Only when setting up an account */}
        <Route exact path={Routes.Root} render={redirectToStart} />
        <Route exact component={StartPage} path={Routes.Start} />
        <Route component={VehiclesPage} path={Routes.Vehicles} />
        <Route exact component={ServicesPage} path={Routes.Services} />

        {/* Routes only for private customer */}
        {authenticationContext.customerType === 'private' ? (
          <>
            <Route exact component={ProfilePage} path={Routes.Profile} />
          </>
        ) : null}

        <Route component={ErrorPage} />
      </Switch>
    )
  }

  return (
    <Router>
      <Suspense fallback={<StyledLoadingIndicator />}>
        <Switch>
          <Route component={BusinessLoginPage} path={Routes.BusinessLogin} />
          <Route component={BankIdAuthenticationPage} path={Routes.BankIdAuthentication} />
          <Route component={ResetPasswordPage} path={Routes.ResetPassword} />
          <Route component={RegistrationPage} path={Routes.Registration} />
          <Route exact component={LandingPage} path={Routes.Root} />
          <Route render={authHandler} />
        </Switch>
      </Suspense>
    </Router>
  )
}
