import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers'

import { Button, Checkbox, FormErrorMessage, Input } from 'components'
import { StyledFormGroup } from './UserRegistrationForm.styles'
import { ContactFormSchema } from './UserRegistrationForm.validation'
import { UserRegistrationFormContactData } from './UserRegistrationForm.types'
import { APP_DATA } from 'config'

export const ContactInfoForm = ({
  onSubmit,
  disableSubmitButton,
}: {
  onSubmit: (data: UserRegistrationFormContactData) => void
  disableSubmitButton: boolean
}): JSX.Element => {
  const [acceptAgreement, setAcceptAgreement] = useState(false)
  const { t } = useTranslation('registration')
  const { control, register, handleSubmit, formState, errors } = useForm<UserRegistrationFormContactData>({
    resolver: yupResolver(ContactFormSchema),
    mode: 'onBlur',
  })

  const isDisabled =
    disableSubmitButton || formState.isSubmitting || !formState.isDirty || !formState.isValid || !acceptAgreement

  function toggleTerms(): void {
    setAcceptAgreement(!acceptAgreement)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledFormGroup>
        <label htmlFor="email">{t('common:email')}</label>
        <Input
          autoComplete="email"
          className={errors.email ? 'danger' : 'basic'}
          inputRef={register}
          name="email"
          placeholder={t('enter-email')}
        />
        {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
      </StyledFormGroup>
      <StyledFormGroup>
        <label htmlFor="phone">{t('common:phone')}</label>
        <Input
          autoComplete="phone"
          className={errors.phone ? 'danger' : 'basic'}
          inputRef={register}
          name="phone"
          placeholder={t('enter-phone')}
        />
        {errors.phone && <FormErrorMessage>{errors.phone.message}</FormErrorMessage>}
      </StyledFormGroup>
      <Controller
        as={Checkbox}
        checked={acceptAgreement}
        control={control}
        label={
          <Trans i18nKey="user-agreement">
            Jag godkänner Opus Bilprovnings
            <a href="https://opus.se/anvandarvilkor-for-kundportalen" rel="noopener noreferrer" target="_blank">
              villkor
            </a>
            , hantering av
            <a href="https://opus.se/integritetspolicy-kundportalen" rel="noopener noreferrer" target="_blank">
              personuppgifter (GDPR)
            </a>
            och
            <a href={APP_DATA.COOKIE_POLICY_URL} rel="noopener noreferrer" target="_blank">
              Cookiepolicy
            </a>
            .
          </Trans>
        }
        name="userAgreement"
        onClick={toggleTerms}
      />
      <Button disabled={isDisabled} type="submit" variant="accent">
        {t('common:next')}
      </Button>
    </form>
  )
}
