import * as React from 'react'
import styled from 'styled-components/macro'

import { Card } from '../../components/Card'

const StyledNewsCard = styled(Card)`
  h1 {
    margin-bottom: 1rem;

    font-size: 1.5rem;
    line-height: 1.25;

    color: ${props => props.theme.colors.primary};
  }
`

export interface NewsCardProps {
  /** A title to display as heading */
  title: string
  /** A list of strings to render as the body text */
  paragraphs: string[]
}

/** A specific type of card to be used as primary content */
export const NewsCard: React.FunctionComponent<NewsCardProps> = ({ title, paragraphs }) => {
  return (
    <StyledNewsCard>
      <h1>{title}</h1>
      {paragraphs.map((paragraph, idx) => (
        <p key={idx} dangerouslySetInnerHTML={{ __html: paragraph }} />
      ))}
    </StyledNewsCard>
  )
}

export default NewsCard
