import * as React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'

/* Import utilities here */

/* Import components here */
import { Button } from '..'

// CSS
const StyledPagination = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  margin-top: 2rem;

  > button:first-child {
    margin-right: 0;
  }
`

// Props
export interface PaginationProps {
  prevBtnDisabled?: boolean
  prevBtnOnClick(): any
  pageBtnOnClick(toPage: number): any
  nextBtnDisabled?: boolean
  nextBtnOnClick(): any
  pageIndex: number
  pageBufferSize?: number
  totalPages?: number
}

// Component
export const Pagination: React.FunctionComponent<PaginationProps> = ({
  children,
  prevBtnDisabled,
  prevBtnOnClick,
  pageBtnOnClick,
  nextBtnDisabled,
  nextBtnOnClick,
  pageIndex,
  pageBufferSize = 3,
  totalPages,
  ...props
}) => {
  return (
    <StyledPagination {...props}>
      <Button disabled={prevBtnDisabled || pageIndex - 1 <= 0} variant="primary" onClick={prevBtnOnClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </Button>
      <div>
        {
          // eslint-disable-next-line compat/compat
          Array.from({
            length: pageIndex >= Math.ceil(pageBufferSize / 2) ? Math.floor(pageBufferSize / 2) : pageIndex - 1,
          })
            .map((_, idx) => (
              <Button
                key={pageIndex + idx}
                data-hello={idx}
                variant="plain"
                onClick={() => pageBtnOnClick(pageIndex - (idx + 1))}
              >
                {pageIndex - (idx + 1)}
              </Button>
            ))
            .reverse()
        }
        <Button variant="primary">{pageIndex}</Button>
        {
          // eslint-disable-next-line compat/compat
          Array.from({
            length:
              pageIndex >= Math.ceil(pageBufferSize / 2) ? Math.floor(pageBufferSize / 2) : pageBufferSize - pageIndex,
          }).map(
            (_, idx) =>
              totalPages &&
              pageIndex + (idx + 1) <= totalPages && (
                <Button key={pageIndex + idx} variant="plain" onClick={() => pageBtnOnClick(pageIndex + (idx + 1))}>
                  {pageIndex + (idx + 1)}
                </Button>
              ),
          )
        }
      </div>
      <Button disabled={nextBtnDisabled || pageIndex === totalPages} variant="primary" onClick={nextBtnOnClick}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </StyledPagination>
  )
}

/** @component */
export default Pagination
