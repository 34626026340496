import * as React from 'react'

import { useTranslation } from 'react-i18next'

/* Import utilities here */
import { WithAuthenticationContext_DEPRECATED, withAuthenticationContext_DEPRECATED } from '../../contexts'

/* Import components here */
import { Page } from '../layout'
import { Button } from '../Button'

// Interfaces
export type IAuthenticatedPageProps = WithAuthenticationContext_DEPRECATED

// Component
const AuthenticatedPage: React.FunctionComponent<IAuthenticatedPageProps> = ({
  children,
  authenticationContext,
  ...props
}) => {
  const { t } = useTranslation('common')

  return (
    <Page
      buttonArea={
        <Button narrow variant="secondary" onClick={authenticationContext.logout}>
          {t('log-out')}
        </Button>
      }
      {...props}
    >
      {children}
    </Page>
  )
}

/** @component */
export default withAuthenticationContext_DEPRECATED()(AuthenticatedPage)
