import * as React from 'react'
import { withFormik } from 'formik'
import { WithTranslation, withTranslation } from 'react-i18next'

/* Import utilities here */
import { validationSchema } from './validationSchema'

/* Import components here */
import { InnerVehicleFilterForm, OtherProps, VehicleFilterFormValues } from './Form'

// interface  s
export interface FilterFormProps extends OtherProps, VehicleFilterFormValues, WithTranslation {
  submitHandler?(values: VehicleFilterFormValues): any
}

// Component
export const VehicleFilterForm = React.memo(
  withTranslation()(
    withFormik<FilterFormProps, VehicleFilterFormValues>({
      mapPropsToValues: (props: VehicleFilterFormValues) => {
        return {
          OrgNo: props.OrgNo || '',
          IsDrivingBan: props.IsDrivingBan || '',
          IsInTraffic: props.IsInTraffic || '',
          LastInspectionMonth: props.LastInspectionMonth || '',
          RegNo: props.RegNo || '',
          VehicleType: props.VehicleType || '',
          Alias: props.Alias || '',
        }
      },
      handleSubmit: (
        { Alias, IsDrivingBan, IsInTraffic, LastInspectionMonth, OrgNo, VehicleType, RegNo }: VehicleFilterFormValues,
        { props },
      ) => {
        if (props.submitHandler) {
          props.submitHandler({
            Alias: (Alias && Alias.length && Alias) || undefined,
            IsDrivingBan: (InnerVehicleFilterForm.YesNoUndefinedToBoolean(IsDrivingBan) as unknown) as string,
            IsInTraffic: (IsInTraffic && IsInTraffic.toString().length && IsInTraffic) || undefined,
            LastInspectionMonth:
              (LastInspectionMonth && LastInspectionMonth.length && LastInspectionMonth) || undefined,
            OrgNo: (OrgNo && OrgNo.length && OrgNo) || undefined,
            VehicleType: (VehicleType && VehicleType.length && VehicleType) || undefined,
            RegNo: (RegNo && RegNo.length && RegNo) || undefined,
          })
        }
      },
      validationSchema,
    })(InnerVehicleFilterForm),
  ),
)

/** @component */
export default VehicleFilterForm
