import React, { memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { AuthenticationContext } from 'contexts'

// Import components
import { AuthenticatedPage, Card, NewsCard } from 'components'
import { ContentArea, Layout, Sidebar } from 'components/styled'
import { ContactAsideCard, DocumentsAsideCard } from 'containers'

// Import data
import { information, servicesPrivate } from 'data/services'
import { TipsPartial } from 'components/TipsPartial'
import { responsiveUp } from 'theme/breakpoints'

const StyledHeader = styled.h1`
  margin-bottom: 1rem;

  font-size: 1.5rem;
  line-height: 1.25;

  color: ${props => props.theme.colors.primary};
`

const Bold = styled.div`
  font-weight: bold;
  line-height: 2rem;
`
const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${responsiveUp.xs`
    grid-template-columns: 1fr 1fr;
  `}
`

const Sublist = styled.ul``
const SublistItem = styled.li`
  list-style-type: none;
  color: #00968f;
`

const Paragraph = styled.p`
  margin-bottom: 2rem;
`

const BusinessContent = function BusinessContent() {
  return (
    <>
      {information.map((infoItem, idx) => (
        <NewsCard key={idx} paragraphs={infoItem.paragraphs} title={infoItem.title} />
      ))}
    </>
  )
}

const isString = (obj: any): obj is string => typeof obj === 'string'

const renderInfoItem = (infoItem: string | { section: string; items: string[] }, idx: number) => {
  if (isString(infoItem)) return <Bold key={idx}>{infoItem}</Bold>
  return (
    <div key={idx}>
      <Bold>{infoItem.section}:</Bold>
      <Sublist>
        {infoItem.items.map((item, subIdx) => (
          <SublistItem key={`${idx}-${subIdx}`}>{item}</SublistItem>
        ))}
      </Sublist>
    </div>
  )
}

const PrivateContent = function PrivateContent() {
  return (
    <Card>
      <StyledHeader>Opus besiktar alla fordon</StyledHeader>
      <Paragraph>
        Opus Bilprovning är en helhetsleverantör av fordonsbesiktning i Sverige och erbjuder besiktning för alla typer
        av fordon. Vi gör också många olika fordonstester som är passande när man vill göra en extra kontroll av sitt
        fordon eller när man ska köpa/sälja.
      </Paragraph>
      <TwoColumns className="two-colums">{servicesPrivate.map(renderInfoItem)}</TwoColumns>
    </Card>
  )
}

// Component
export const ServicesPage: React.FC = memo(function ServicesPage() {
  const { t } = useTranslation('services')
  const authContext = useContext(AuthenticationContext)

  return (
    <AuthenticatedPage>
      <Layout>
        <Sidebar>
          <Card>
            <DocumentsAsideCard />
            <TipsPartial />
          </Card>
        </Sidebar>
        <ContentArea className="service-page-content">
          <h1>{t('page-title')}</h1>
          {authContext.customerType === 'private' ? <PrivateContent /> : <BusinessContent />}
        </ContentArea>
        <Sidebar>
          <ContactAsideCard />
        </Sidebar>
      </Layout>
    </AuthenticatedPage>
  )
})
