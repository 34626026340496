export enum TrafficStatus {
  inTraffic = 'ITRAFIK',
  unregistered = 'AVST',
  unset = -1,
}

export enum TrafficStatusValue {
  ITRAFIK = 1,
  AVST = 0,
  unset = -1,
}
