import * as yup from 'yup'
import { getI18n } from 'react-i18next'

getI18n().setDefaultNamespace('registration')

export const ContactFormSchema = yup.object().shape({
  email: yup.string().email(getI18n().t('email-valid')).required(getI18n().t('email-required')),
  phone: yup
    .string()
    .required(getI18n().t('phone-required'))
    .test('is-valid-phone', getI18n().t('phone-valid'), value => {
      if (value === undefined || value === null) return true
      return /^(?:(?:\+46|0046)|0)(?:10|70|72|73|76|79)[0-9]{7,13}$/.test(value.replace(/\s+|-+/g, ''))
    }),
  userAgreement: yup.string(),
})

export const VehicleFormSchema = yup.object().shape({
  regNo: yup.string().required(getI18n().t('plate-number-required')),
})
