import styled from 'styled-components/macro'
import { StyledButton } from 'components/Button'
import { responsiveUp } from 'theme/breakpoints'

export const FormRow = styled.div`
  margin-bottom: 2rem;
  &:nth-of-type(4) {
    ${responsiveUp.md`
      margin-bottom: 0;
    `}
  }
`

export const LicenseAndPolicyWrapper = styled.p`
  margin-top: 2rem;
`

export const SubmitRow = styled(FormRow)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;

  &:last-of-type {
    flex-direction: column;

    ${responsiveUp.md`
      flex-flow: row-reverse;
    `}

    ${StyledButton} {
      width: 100%;

      ${responsiveUp.md`
        width: auto;
      `}
    }
  }
`
export const StyledSuccessMessage = styled.span`
  display: block;

  padding: 0.5rem;
  margin-top: 0.5rem;

  font-weight: 600;

  color: white;
  background: ${props => props.theme.colors.green};

  border-radius: 0.25rem;

  &:first-child {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`
