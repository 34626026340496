/** Converts tabs to &emsp; */
export const tabToEmsp = (string: string): string => string.replace(/\t/g, '&emsp;&emsp;')

/**
 * Finds all matched RegEx groups for a string against a pattern
 * @param string The string to search
 * @param pattern A RegEx pattern to match against
 */
export function searchString(string: string, pattern: RegExp): (RegExpExecArray | null)[] {
  return (string.match(new RegExp(pattern.source, pattern.flags)) as []).map(match =>
    new RegExp(pattern.source, pattern.flags).exec(match),
  )
}

/**
 * Capitalizes a string
 * @param string The string to capitalize
 */
export function capitalize(string: string): string {
  const lower = string.toLowerCase()
  const match = searchString(lower, /(?:([\wåäö]{1})([\wåäö]*))/g)

  const capitalized = match.map(execArray => execArray && `${execArray[1].toUpperCase()}${execArray[2]}`)

  return capitalized.join(' ')
}

/**
 *
 * @param orgno
 */
export function toOrgNoFormat(orgno: string): string {
  if (orgno.length !== 10) {
    console.warn('Org.no needs to be 10 characters. Perhaps this is an identity number?')
    return orgno
  }

  return `${orgno.substr(0, 6)}-${orgno.substr(6)}`
}

/** Formats a regno according to the AAA 111 format */
export function toStandardRegNoFormat(regno: string): string {
  if (regno.length !== 6) {
    console.warn('Reg.no is usually 6 characters, so skipping formatting')
    return regno
  }

  return `${regno.substr(0, 3)} ${regno.substr(3)}`
}

/**
 * Fix type of x, remove null
 */
export const nonull = <T extends unknown>(x: T | undefined | null): T | undefined => x as T | undefined
