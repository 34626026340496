import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import logo from './img/opus-logo.svg'

import { Routes } from 'config'
import { PropsWithChildren, useCallback, useContext, useState } from 'react'
import { AuthenticationContext } from 'contexts'
import { TCustomerChoice } from 'utils'
import { responsiveDown, responsiveUp } from 'theme/breakpoints'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-solid-svg-icons'

const StyledHeader = styled.header`
  width: 100%;
  background: white;

  padding-top: 0.2rem;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .header {
    display: flex;
    align-items: center;
  }

  .header a {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1;
    color: ${({ theme }) => theme.colors.primary};

    border: none;
  }
`

const HeaderLogo = React.memo(styled.img`
  height: 1.5rem;
  margin-right: 0.5rem;

  ${responsiveUp.xs`
    height: 3rem;
    margin-right: 1rem;
  `}
`)

const HeaderLink = React.memo(
  styled.div`
    text-transform: uppercase;
    line-height: 1rem;
    white-space: nowrap;

    a {
      display: inline-block;

      padding: 1.5rem 1rem;

      font-weight: 600;
      color: ${props => props.theme.colors.primary};

      border: none;
    }

    .active {
      color: ${props => props.theme.colors.secondary};
      text-decoration: underline;

      ${responsiveUp.sm`
        text-decoration: none;
        box-shadow: inset 0 0.25rem ${props => props.theme.colors.secondary};
      `}
    }
  `,
  (prev, next) => {
    if (prev.className !== next.className) {
      return false
    }

    return true
  },
)

const TypeText = styled.span``

const Title = styled.span`
  white-space: nowrap;
  ${responsiveDown.xs`
    line-height: 2;
    font-size: 0.8rem;
  `}
`

const MobileMenu = styled.nav`
  display: block;
  width: 100%;
  padding-bottom: 0.3rem;

  ${responsiveUp.sm`
    display: none;
  `}
`

const MobileMenuHead = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  color: ${({ theme }) => theme.colors.primary};
`

const DesktopMenu = styled.nav`
  display: none;
  align-items: center;

  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${responsiveUp.sm`
    display: flex;
  `}
`

export interface HeaderProps {
  hideNav?: boolean
  hideType?: boolean
  buttonArea?: React.ReactNode

  /**
   * The currect user type. Used to display correct title in header.
   */
  userType?: TCustomerChoice
}

const Menu = function (props: PropsWithChildren<{ buttonArea?: React.ReactNode }>): JSX.Element {
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = useCallback(() => {
    setShowMenu(show => !show)
  }, [])
  return (
    <>
      <MobileMenu>
        <MobileMenuHead>
          <FontAwesomeIcon icon={faBars} size="2x" onClick={toggleMenu} />
          <div>{props.buttonArea}</div>
        </MobileMenuHead>
        {showMenu && <nav>{props.children}</nav>}
      </MobileMenu>
      <DesktopMenu>
        {props.children}
        {props.buttonArea}
      </DesktopMenu>
    </>
  )
}

export const Header: React.FunctionComponent<HeaderProps> = ({ buttonArea, hideNav, hideType, userType }) => {
  const { t } = useTranslation('app')
  const authContext = useContext(AuthenticationContext)

  const title = userType === 'company' ? t('type.company') : userType === 'private' ? t('type.private') : undefined

  return (
    <StyledHeader>
      <div className="container">
        <div className="header">
          <a href="https://opus.se/" rel="noopener noreferrer" target="_blank">
            <HeaderLogo alt={t('name')} src={logo} />
          </a>
          <Link to={Routes.Root}>
            <Title>
              {t('name')}
              {!hideType && title && <TypeText> – {title}</TypeText>}
            </Title>
          </Link>
        </div>
        {hideNav || (
          <Menu buttonArea={buttonArea}>
            <HeaderLink>
              <NavLink to={Routes.Start}>{t('navigation.start')}</NavLink>
            </HeaderLink>
            <HeaderLink>
              <NavLink to={Routes.Vehicles}>{t('navigation.vehicles-overview')}</NavLink>
            </HeaderLink>
            <HeaderLink>
              <NavLink to={Routes.Services}>{t('navigation.more-services')}</NavLink>
            </HeaderLink>

            {authContext.customerType === 'private' && (
              <HeaderLink>
                <NavLink to={Routes.Profile}>{t('navigation.profile')}</NavLink>
              </HeaderLink>
            )}
          </Menu>
        )}
      </div>
    </StyledHeader>
  )
}

export default React.memo(Header)
