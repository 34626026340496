import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers'

import { Button, FormErrorMessage, Input } from 'components'
import { StyledButtonGroup, StyledFormGroup } from './UserRegistrationForm.styles'
import { VehicleFormSchema } from './UserRegistrationForm.validation'
import { UserRegistrationFormVehicleData } from './UserRegistrationForm.types'

export const VehicleInfoForm = ({
  onSubmit,
  disableSubmitButton,
  noVehicles,
}: {
  disableSubmitButton: boolean
  onSubmit: (data: UserRegistrationFormVehicleData) => void
  noVehicles: () => void
}): JSX.Element => {
  const { t } = useTranslation('registration')
  const { register, handleSubmit, formState, errors } = useForm<UserRegistrationFormVehicleData>({
    resolver: yupResolver(VehicleFormSchema),
    mode: 'onBlur',
  })

  const isDisabled = disableSubmitButton || formState.isSubmitting || !formState.isDirty || !formState.isValid

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledFormGroup>
        <label htmlFor="regNo">{t('vehicles:registration-number')}</label>
        <Input className={errors.regNo ? 'danger' : 'basic'} inputRef={register} name="regNo" placeholder="ABC 123" />
        {errors.regNo && <FormErrorMessage>{errors.regNo.message}</FormErrorMessage>}
      </StyledFormGroup>
      <StyledButtonGroup className="button-group">
        <div style={{ display: 'none' }}>
          <input name="save" type="submit" />
        </div>
        <Button onClick={noVehicles}>{t('i-do-not-own-any-vehicles')}</Button>
        <Button disabled={isDisabled} name="save" type="submit" variant="accent">
          {t('common:save')}
        </Button>
      </StyledButtonGroup>
    </form>
  )
}
