import React, { useState } from 'react'

import { FutureBookingInfo, UserVehicleInfo, VehicleBasicInfo, api } from 'api'

interface VehicleBookingsMap {
  vehicle: VehicleBasicInfo
  booking: FutureBookingInfo
}

export interface VehiclesContext {
  loading: boolean
  fetchedVehicles?: VehicleBasicInfo[]
  fetchedVehiclesError: boolean
  fetchedBookings?: FutureBookingInfo[]
  fetchedBookingsError: boolean
  vehicleBookingsMap: VehicleBookingsMap[]
  fetchVehicles(): Promise<VehicleBasicInfo[] | undefined>
  fetchVehiclesAndBookings(): Promise<void>
  updateUserVehicleInfo(vehicleInfo: UserVehicleInfo): void
  totalVehicles: number
  /**
   * Get a specific vehicle from context.
   *
   * @param plateNumber Plate number of the vehicle
   *
   * @returns A vehicle if it exists, otherwise null
   */
  getVehicleFromContext(plateNumber: string): VehicleBasicInfo | null
}

/**
 * Default state for VehicleContext
 */
const initialContext: VehiclesContext = {
  loading: false,
  fetchedVehicles: [],
  fetchedVehiclesError: false,
  fetchedBookings: [],
  fetchedBookingsError: false,
  vehicleBookingsMap: [],
  fetchVehiclesAndBookings: async () => undefined,
  fetchVehicles: async () => undefined,
  updateUserVehicleInfo: () => null,
  totalVehicles: 0,
  getVehicleFromContext: () => null,
}

export const VehiclesContext = React.createContext<VehiclesContext>(initialContext)

export const VehiclesProvider: React.FC = ({ children }) => {
  const [loading, setIsLoading] = useState<boolean>(false)
  const [fetchedVehicles, setFetchedVehicles] = useState<VehicleBasicInfo[] | undefined>(undefined)
  const [fetchedVehiclesError, setFetchedVehiclesError] = useState(false)
  const [fetchedBookings, setFetchedBookings] = useState<FutureBookingInfo[] | undefined>(undefined)
  const [fetchedBookingsError, setFetchedBookingsError] = useState(false)
  const [vehicleBookingsMap, setVehicleBookingsMap] = useState<VehicleBookingsMap[]>([])
  const [totalVehicles, setTotalVehicles] = useState(0)

  async function fetchVehicles(): Promise<VehicleBasicInfo[] | undefined> {
    try {
      const { data } = await api.vehicles.getVehicles()
      return data
    } catch (e) {
      console.warn(e)
      return undefined
    }
  }

  async function fetchBookings(): Promise<FutureBookingInfo[] | undefined> {
    try {
      const { data } = await api.bookings.getBookings()
      return data
    } catch (e) {
      return undefined
    }
  }

  /** Sorts a list of vehicles by the inspection deadline */
  function sortVehiclesByInspectionDeadline(a: VehicleBasicInfo, b: VehicleBasicInfo): number {
    if (a.InspectionDeadline === null) return Infinity
    if (b.InspectionDeadline === null) return -Infinity
    return new Date(a.InspectionDeadline).getTime() - new Date(b.InspectionDeadline).getTime()
  }

  async function fetchVehiclesAndBookings(): Promise<void> {
    try {
      setIsLoading(true)
      // eslint-disable-next-line compat/compat
      const data = await Promise.all([fetchVehicles(), fetchBookings()])

      const vehicles: VehicleBasicInfo[] | undefined = data[0] || fetchedVehicles || undefined
      const bookings: FutureBookingInfo[] | undefined = data[1] || fetchedBookings || undefined
      if (vehicles) {
        vehicles.sort(sortVehiclesByInspectionDeadline)
      }

      const vehicleBookingsMap: VehicleBookingsMap[] = []

      if (vehicles && bookings) {
        vehicles.forEach(vehicle => {
          const booking = bookings.find(booking =>
            booking.Items ? !!booking.Items.find(item => item.RegNo === vehicle.RegNo) : false,
          )
          if (!booking) return

          vehicleBookingsMap.push({
            vehicle,
            booking,
          })
        })
      }

      setFetchedVehicles(vehicles)
      setFetchedVehiclesError(data[0] === undefined)
      setFetchedBookings(bookings)
      setFetchedBookingsError(data[1] === undefined)
      setVehicleBookingsMap(vehicleBookingsMap)
      setTotalVehicles(vehicles ? vehicles.length : 0)
    } catch (thrown) {
      console.error(thrown)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Update data saved in VehicleContext after making changes on a vehicle. This is required since we only fetch the vehicles when logging in.
   */
  function updateUserVehicleInfo(updatedVehicleInfo: UserVehicleInfo): void {
    if (!fetchedVehicles) return

    const updatedVehicleState = fetchedVehicles.map(vehicle => {
      if (vehicle.RegNo === updatedVehicleInfo.RegNo) {
        return {
          ...vehicle,
          Alias: updatedVehicleInfo.VehicleAlias || '',
          SendEmail: updatedVehicleInfo.SendEmail,
          SendSMS: updatedVehicleInfo.SendSMS,
          VehicleUserEmail: updatedVehicleInfo.VehicleUserEmail,
          VehicleUserName: updatedVehicleInfo.VehicleUserName,
          VehicleUserNotes: updatedVehicleInfo.VehicleUserNotes,
          VehicleUserPhone: updatedVehicleInfo.VehicleUserPhone,
        }
      }
      return vehicle
    })

    setFetchedVehicles(updatedVehicleState)
  }

  function getVehicleFromContext(plateNumber: string): VehicleBasicInfo | null {
    if (!fetchedVehicles) return null
    const vehicle = fetchedVehicles.find(vehicle => vehicle.RegNo === plateNumber)

    return vehicle || null
  }

  return (
    <VehiclesContext.Provider
      value={{
        fetchedBookings,
        loading,
        fetchVehicles,
        fetchedVehicles,
        fetchedVehiclesError,
        fetchedBookingsError,
        vehicleBookingsMap,
        fetchVehiclesAndBookings,
        updateUserVehicleInfo,
        totalVehicles,
        getVehicleFromContext,
      }}
    >
      {children}
    </VehiclesContext.Provider>
  )
}

export const VehiclesConsumer = VehiclesContext.Consumer

/**
 * HOC to add context to a component.
 *
 * Use the hook `useContext` instead.
 *
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/class-name-casing
export interface WithVehiclesContext_DEPRECATED {
  vehiclesContext: VehiclesContext
}

/**
 * Please migrate to `useContext()` hook instead.
 * @deprecated
 */
export function withVehiclesContext_DEPRECATED<P>() {
  return function Wrapper(WrapperComponent: React.ComponentType<any>): React.ComponentType<any> {
    class WithContext extends React.Component {
      render(): JSX.Element {
        const { ...props } = this.props

        return React.createElement(VehiclesConsumer, null, (ctx: VehiclesContext) =>
          React.createElement(WrapperComponent, {
            vehiclesContext: {
              ...ctx,
            },
            ...props,
          }),
        )
      }
    }

    return WithContext as React.ComponentType<P>
  }
}
