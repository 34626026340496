import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  RegNo: Yup.string().max(7),
  OrgNo: Yup.string().max(11),
  IsDrivingBan: Yup.string(),
  IsInTraffic: Yup.string(),
  LastInspectionMonth: Yup.string(),
  VehicleType: Yup.string(),
  Alias: Yup.string().max(10),
})
