import * as React from 'react'
import styled from 'styled-components/macro'
import classNames from 'classnames'
import { FieldProps } from 'formik'
import { NotVoid } from 'lodash'

const StyledInput = styled.input`
  appearance: none;

  width: 100%;
  height: 3rem;

  padding: 0 1rem;

  font: inherit;
  line-height: 1.5;

  background: white;

  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: ${props => props.theme.borderRadius};

  &.plain {
    border-color: transparent;
  }

  &.danger {
    color: ${props => props.theme.colors.red};
    border-color: ${props => props.theme.colors.red};
  }
`

//interface Values {
//  field: string
//}
type Values = React.InputHTMLAttributes<HTMLInputElement>['type']

export interface InputProps extends Partial<FieldProps<Values>> {
  inputRef?: (instance: HTMLInputElement | null) => NotVoid
  inputType?: 'text' | 'password' | 'email' | 'date' | 'time'
  variant?: 'default' | 'plain'
}

/**
 * Form Input
 */
export class Input extends React.Component<InputProps & React.InputHTMLAttributes<unknown>> {
  render() {
    const { variant = 'default', form, inputType = 'text', field, children, inputRef, value, ...props } = this.props

    return (
      <StyledInput
        ref={inputRef}
        className={classNames(variant, props.className)}
        type={inputType}
        value={value}
        {...field}
        {...props}
      >
        {children}
      </StyledInput>
    )
  }
}

/** @component */
export default Input
