import React from 'react'
import styled from 'styled-components/macro'
import { faLink } from '@fortawesome/pro-light-svg-icons'
import { ButtonLink } from '../../components'
import { AuthenticationContext } from 'contexts'
import { useContext } from 'react'

const businessLinks = [
  {
    title: 'Checklista inför besiktning',
    href: 'https://opus.se/nyheter/nyheter/2020-07-27-checklista-infor-besiktning',
  },

  {
    title: 'Klimatkompensera för din körning',
    href: 'https://opus.se/hallbarhet/vart-klimatansvar/klimatkompensera-din-korning',
  },

  {
    title: 'Vattenfall och Opus i samarbete för minskade klimatavtryck',
    href:
      'https://opus.se/nyheter/nyheter/2024-04-24-vattenfall-och-opus-i-samarbete-for-minskade-klimatavtryck?qs=vattenfall',
  },
]

const privateLinks = [
  {
    title: 'Checklista inför besiktning',
    href: 'https://opus.se/nyheter/nyheter/2020-07-27-checklista-infor-besiktning',
  },

  {
    title: 'Klimatkompensera för din körning',
    href: 'https://opus.se/hallbarhet/vart-klimatansvar/klimatkompensera-din-korning',
  },

  {
    title: 'Vattenfall och Opus i samarbete för minskade klimatavtryck',
    href:
      'https://opus.se/nyheter/nyheter/2024-04-24-vattenfall-och-opus-i-samarbete-for-minskade-klimatavtryck?qs=vattenfall',
  },

  {
    title: 'Spara tusenlappar med Vattenfall och Opus Bilprovning',
    href: 'https://www.vattenfall.se/elavtal/opus?pk_campaign=opus100_erbj&pk_source=referral&pk_medium=mypages',
  },
]

const LinkList = styled.ul`
  padding: 0;
`
const ListItem = styled.li`
  list-style-type: none;
  margin-bottom: 2rem;
`

export function StartPageLinks() {
  const authContext = useContext(AuthenticationContext)
  return (
    <LinkList>
      {authContext.customerType === 'private'
        ? privateLinks.map(({ title, href }, idx) => (
            <ListItem key={idx}>
              <ButtonLink alignLeft className="fullWidth" icon={faLink} target="_blank" to={{ pathname: href }}>
                {title}
              </ButtonLink>
            </ListItem>
          ))
        : businessLinks.map(({ title, href }, idx) => (
            <ListItem key={idx}>
              <ButtonLink alignLeft className="fullWidth" icon={faLink} target="_blank" to={{ pathname: href }}>
                {title}
              </ButtonLink>
            </ListItem>
          ))}
    </LinkList>
  )
}
